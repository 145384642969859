/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0; }

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  /* 1 */
  display: block; }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse; }

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0; }

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */ }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  /* 1 */
  touch-action: manipulation; }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default; }

button {
  border: none; }

body {
  /*padding: 1rem;*/
  background-color: #fff; }

html {
  color: #979691;
  font-family: "Cormorant Garamond", serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }

h1 {
  font-family: "Bevan", sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: #d4b86d;
  letter-spacing: 0.03em;
  font-size: 1.5rem;
  font-weight: 400; }

h2 {
  font-family: "Bevan", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #d4b86d;
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 400; }

p {
  margin-top: 0;
  margin-bottom: 1.5rem; }
  p strong {
    font-weight: 700;
    font-family: "Titillium Web", Arial, sans-serif; }

.info {
  color: #d4b86d;
  font-family: "Titillium Web", Arial, sans-serif;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0; }
  @media screen and (min-width: 50em) {
    .info {
      font-size: 1.6rem; } }

.info--location {
  font-size: 0.8rem;
  color: #979691;
  margin-bottom: 1.5rem; }
  @media screen and (min-width: 50em) {
    .info--location {
      font-size: 1rem; } }

.link {
  text-decoration: none; }

.link--slash {
  color: #979691;
  font-family: "Titillium Web", Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold; }
  .link--slash:before {
    content: '/';
    color: #d4b86d;
    margin-right: 1rem; }

header[role="banner"] {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10; }
  header[role="banner"] h1, header[role="banner"] h2 {
    display: none;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    position: relative;
    font-family: "Titillium Web", Arial, sans-serif;
    text-transform: uppercase;
    text-align: center;
    color: #d4b86d;
    letter-spacing: 0.03em;
    font-size: 1.5rem;
    font-weight: 300; }
    header[role="banner"] h1:after, header[role="banner"] h2:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 10%;
      right: 10%;
      height: 1px;
      background-color: #d4b86d;
      transition: 0.25s; }
    @media screen and (min-width: 68em) {
      header[role="banner"] h1, header[role="banner"] h2 {
        display: block; } }

.header__wrapper {
  display: flex;
  flex-direction: row;
  width: 100%; }
  @media screen and (min-width: 68em) {
    .header__wrapper {
      flex-direction: row; } }

.header__button_container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2rem;
  font-size: 0.85em; }

.header__logo {
  padding: 1rem 0.5rem;
  text-align: center; }
  .header__logo img {
    max-height: 2rem;
    width: 50px;
    transform-origin: center center;
    opacity: 1; }
  @media screen and (min-width: 23em) {
    .header__logo img {
      width: 100px; } }
  @media screen and (min-width: 68em) {
    .header__logo {
      text-align: left;
      position: relative;
      z-index: 1;
      padding: 1rem; }
      .header__logo.header__logo--mod-talk {
        padding-left: 4rem; }
      .header__logo img {
        max-height: 4.5rem;
        min-height: 4.5rem;
        width: 100%;
        transform-origin: top center;
        /*transform: scale(0.2) !important;*/
        opacity: 1; } }

.header__middle {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center; }
  @media screen and (min-width: 68em) {
    .header__middle {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0; }
      .home.page .header__middle, .page-template-page-wwt .header__middle, .page-template-page-panel .header__middle {
        padding-bottom: 1rem; } }

.header__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #02579a;
  z-index: -1;
  transform-origin: top center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2); }
  .header__bg.header__bg--mod-wwt {
    background-color: #025835; }

.header__right {
  flex: 1;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding-bottom: 1rem;
  padding-right: 4rem;
  align-items: flex-end;
  justify-content: center;
  display: none; }
  .header__right .navigation li:first-child a {
    color: #00629a; }
  .header__right .navigation li:last-child a {
    color: #d4b86d; }
  @media screen and (min-width: 68em) {
    .header__right {
      /*display: flex;*/ } }

.header-spacing {
  padding-top: 8.5em;
  position: relative;
  height: 4em;
  background-color: #fff;
  width: 100%;
  display: none; }
  @media screen and (min-width: 69em) {
    .header-spacing {
      display: block; } }

@media screen and (max-width: 69em) {
  .header__button_container {
    display: none; } }

.jumbotron {
  width: 100%;
  background-color: #000;
  color: #fff; }
  .jumbotron .sans p {
    font-family: "Titillium Web", Arial, sans-serif;
    font-size: 0.9rem;
    margin-bottom: 1rem; }

.jumbotron__section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 0 2rem; }
  .jumbotron__section--vertical {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
    justify-content: flex-start;
    padding-top: 6rem; }
    @media screen and (min-width: 50em) {
      .jumbotron__section--vertical {
        min-height: 100vh;
        justify-content: center;
        padding-top: 0; } }
  .jumbotron__section h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #d4b86d;
    text-align: center; }
    @media screen and (min-width: 40em) {
      .jumbotron__section h2 {
        font-size: 1.75rem;
        margin-bottom: 1.5rem; } }
    @media screen and (min-width: 50em) {
      .jumbotron__section h2 {
        font-size: 2rem; } }
  .jumbotron__section p {
    font-size: 1.4rem;
    text-align: center;
    font-weight: 300;
    line-height: 1.4; }
    @media screen and (min-width: 40em) {
      .jumbotron__section p {
        font-size: 2rem;
        max-width: 35rem; } }
    @media screen and (min-width: 65em) {
      .jumbotron__section p {
        font-size: 2.2rem;
        max-width: 55rem; } }
    @media screen and (min-width: 95em) {
      .jumbotron__section p {
        font-size: 2.5rem;
        max-width: 65rem; } }
    @media screen and (min-width: 110em) {
      .jumbotron__section p {
        font-size: 3rem;
        max-width: 75rem; } }

.jumbotron__section--logo {
  display: none; }
  @media screen and (min-width: 50em) {
    .jumbotron__section--logo {
      display: flex; } }

.jumbotron__section--centered {
  justify-content: center; }

p.jumbotron__section__info {
  font-size: 1.2rem;
  font-weight: 600; }
  @media screen and (min-width: 40em) {
    p.jumbotron__section__info {
      font-size: 1.25rem; } }
  @media screen and (min-width: 65em) {
    p.jumbotron__section__info {
      font-size: 1.5rem; } }
  @media screen and (min-width: 110em) {
    p.jumbotron__section__info {
      font-size: 1.8rem; } }

.separator {
  display: inline-block;
  margin: 0 0.5em; }

.jumbotron--front {
  background-color: #000;
  position: relative; }

.jumbotron-gradient {
  background: linear-gradient(rgba(0, 0, 0, 0), black);
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0; }

.jumbotron--front {
  background-position: center center;
  background-color: #000;
  background-size: cover; }
  @media screen and (min-width: 50em) {
    .jumbotron--front {
      background-position: fixed center center;
      background-color: #000;
      background-size: cover; } }

/*
.jumbotron--front {
  $gradient: rgba(0, 0, 0, 0.0);
  background: linear-gradient(rgba(#000, 0), rgba(#000, 1)), url('/app/themes/worldwhiskyforum/images/2017_event_ren.jpg') center center;
  background-color: #000;
  background-size: cover;

  @media screen and (min-width: 50em) {
    background: linear-gradient(rgba(#000, 0), rgba(#000, 1)), url('/app/themes/worldwhiskyforum/images/2017_event_ren.jpg') fixed center center;
    background-color: #000;
    background-size: cover;
  }
}
*/
.jumbotron__logo {
  width: 100%;
  max-width: 240px;
  margin-bottom: 1.5rem; }
  @media screen and (min-width: 40em) {
    .jumbotron__logo {
      max-width: 300px;
      margin-bottom: 2.5rem; } }
  @media screen and (min-width: 65em) {
    .jumbotron__logo {
      max-width: 400px;
      margin-bottom: 3rem; } }
  @media screen and (min-width: 110em) {
    .jumbotron__logo {
      max-width: 600px;
      margin-bottom: 3rem; } }

.jumbotron--spacer {
  padding-bottom: 8rem;
  background-color: #fff; }

.jumbotron--coming-soon h2 {
  font-size: 3rem;
  line-height: 1.2; }

@media screen and (min-width: 68em) {
  .jumbotron--coming-soon .jumbotron__section {
    min-height: calc(100vh - 10em); } }

.jumbotron__section__info a {
  color: #fff;
  text-decoration: none; }
  .jumbotron__section__info a:hover {
    border-bottom: solid 1px #fff; }

.jumbotron--default {
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0), black), url("/app/themes/worldwhiskyforum/images/whisky3.jpg") center center;
  background-size: cover;
  padding-top: 4rem;
  align-items: flex-start;
  justify-content: flex-start; }
  @media screen and (min-width: 70em) {
    .jumbotron--default {
      background: linear-gradient(rgba(0, 0, 0, 0), black), url("/app/themes/worldwhiskyforum/images/whisky3.jpg") fixed center center;
      background-size: cover;
      padding: 8rem 2rem 0; } }
  @media screen and (min-width: 90em) {
    .jumbotron--default {
      padding: 10rem 2rem 0; } }
  .jumbotron--default h1 {
    font-size: 1.6rem;
    line-height: 1.3;
    text-align: left;
    margin: 0 0 1.5rem; }
    @media screen and (min-width: 40em) {
      .jumbotron--default h1 {
        font-size: 2.5rem; } }
    @media screen and (min-width: 80em) {
      .jumbotron--default h1 {
        font-size: 4rem;
        max-width: 800px; } }
  .jumbotron--default h2 {
    text-align: left; }
  .jumbotron--default p {
    font-size: 1.3rem;
    color: #fff;
    text-align: left; }
    @media screen and (min-width: 60em) {
      .jumbotron--default p {
        font-size: 1.15rem; } }
    @media screen and (min-width: 100em) {
      .jumbotron--default p {
        font-size: 1.3rem; } }
  .jumbotron--default .jumbotron__section {
    padding: 0 1rem;
    justify-content: flex-start; }
    @media screen and (min-width: 50em) {
      .jumbotron--default .jumbotron__section {
        padding: 0 2rem; } }
  .jumbotron--default.jumbotron--no-image .jumbotron__section {
    padding: 0 1rem; }
    @media screen and (min-width: 50em) {
      .jumbotron--default.jumbotron--no-image .jumbotron__section {
        padding: 6rem 2rem 0; } }

.jumbotron__multiple {
  display: flex;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column; }
  @media screen and (min-width: 70em) {
    .jumbotron__multiple {
      flex-direction: row; } }

.jumbotron__single {
  flex: 1; }
  .jumbotron__single p.jumbotron__single__info {
    color: #d4b86d;
    margin-bottom: 1.5rem;
    display: none; }
    @media screen and (min-width: 50em) {
      .jumbotron__single p.jumbotron__single__info {
        display: block; } }
  .jumbotron__single img {
    max-width: 100%; }

.jumbotron__heading-wrapper {
  max-width: 1500px;
  width: 100%;
  text-align: left; }

.jumbotron__single.section--text-columns {
  margin-top: 2rem;
  padding: 0; }
  @media screen and (min-width: 70em) {
    .jumbotron__single.section--text-columns {
      padding: 0 0 0 4rem;
      margin-top: 0; }
      .jumbotron__single.section--text-columns .text-column__inner:first-child {
        padding-right: 0; } }

@-webkit-keyframes mouseAnimation {
  from {
    transform: translate(-50%, 150%); }
  to {
    transform: translate(-50%, 0%); } }

@keyframes mouseAnimation {
  from {
    transform: translate(-50%, 150%); }
  to {
    transform: translate(-50%, 0%); } }

.jumbotron__scroll {
  position: absolute;
  bottom: 11rem;
  left: 50%;
  transform: translate3D(-50%, -50%, 0);
  display: none; }
  @media screen and (min-height: 50em) and (min-width: 50em) {
    .jumbotron__scroll {
      display: block; } }

.jumbotron--default .jumbotron__scroll {
  bottom: 2rem;
  display: none; }

.jumbotron--small {
  min-height: inherit;
  height: auto;
  background-color: transparent;
  max-width: 1500px;
  margin: 0 auto; }
  .jumbotron--small .jumbotron__section {
    height: auto;
    padding: 6rem 1rem 0; }
    @media screen and (min-width: 50em) {
      .jumbotron--small .jumbotron__section {
        padding: 10rem 4rem 0; } }
  .jumbotron--small h1 {
    font-size: 2rem;
    line-height: 1.3;
    text-align: left;
    margin: 0 0 1.5rem; }
    @media screen and (min-width: 40em) {
      .jumbotron--small h1 {
        font-size: 2.5rem; } }
    @media screen and (min-width: 50em) {
      .jumbotron--small h1 {
        font-size: 3rem; } }
    @media screen and (min-width: 60em) {
      .jumbotron--small h1 {
        font-size: 3.5rem; } }
    @media screen and (min-width: 80em) {
      .jumbotron--small h1 {
        font-size: 5rem;
        max-width: 800px; } }
  .blog .jumbotron--small .jumbotron__section,
  .search .jumbotron--small .jumbotron__section,
  .archive .jumbotron--small .jumbotron__section {
    max-width: 80rem;
    height: auto;
    padding: 6rem 1rem 0;
    margin: 0 auto; }
    @media screen and (min-width: 50em) {
      .blog .jumbotron--small .jumbotron__section,
      .search .jumbotron--small .jumbotron__section,
      .archive .jumbotron--small .jumbotron__section {
        padding: 10rem 2rem 0; } }
  .blog .jumbotron--small h1,
  .search .jumbotron--small h1,
  .archive .jumbotron--small h1 {
    font-size: 3rem; }

.jumbotron--post {
  max-width: 900px; }
  .jumbotron--post .jumbotron__section {
    padding: 6rem 1rem 0; }
    @media screen and (min-width: 50em) {
      .jumbotron--post .jumbotron__section {
        padding: 10rem 1rem 0; } }
  .jumbotron--post h1 {
    font-size: 2rem; }
    @media screen and (min-width: 40em) {
      .jumbotron--post h1 {
        font-size: 2.5rem; } }
    @media screen and (min-width: 50em) {
      .jumbotron--post h1 {
        font-size: 3rem; } }
    @media screen and (min-width: 60em) {
      .jumbotron--post h1 {
        font-size: 3.5rem; } }

.jumbotron__section--vertical-center {
  padding-top: 6em;
  padding-bottom: 4em;
  align-items: center;
  justify-content: center; }
  @media screen and (min-width: 40em) {
    .jumbotron__section--vertical-center {
      padding-top: 0;
      padding-bottom: 4em; } }

.info__span {
  display: block; }
  @media screen and (min-width: 40em) {
    .info__span {
      display: inline-block; } }

.separator {
  display: none; }
  @media screen and (min-width: 40em) {
    .separator {
      display: inline-block; } }

@media screen and (min-width: 40em) {
  .top-content {
    transform: translateY(-6em); } }

.top-content__first-heading {
  font-family: Titillium Web;
  text-transform: uppercase;
  color: #d4b86d;
  letter-spacing: 0em;
  font-weight: 200;
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.4; }

.top-content__next-heading {
  color: #000;
  font-family: "Titillium Web", Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 2rem;
  text-align: center;
  padding-bottom: 0.5em; }

@media screen and (max-width: 40em) {
  .top-content__first-heading {
    font-size: 1.4rem; }
  .top-content__next-heading {
    font-size: 1.4rem; } }

.navigation {
  font-family: "Titillium Web", Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 600; }
  .navigation ul {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: center; }
  .navigation li.menu-item-5, .navigation .menu-item-577 {
    display: none; }
    @media screen and (min-width: 40em) {
      .navigation li.menu-item-5, .navigation .menu-item-577 {
        display: flex; } }
  .navigation li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem; }
    .navigation li:not(:last-child):not(.mobile-only-link):not(:nth-last-child(2)):after {
      content: '/';
      margin: 0 0 0 0.5rem;
      color: #d4b86d; }
    .navigation li.current-menu-item a {
      color: #d4b86d; }
      @media screen and (min-width: 69em) {
        .navigation li.current-menu-item a {
          color: #fff; } }
    @media screen and (min-width: 69em) {
      .navigation li.mobile-only-link {
        display: none; } }
  .navigation a {
    color: #979691;
    text-decoration: none;
    font-size: 0.9rem;
    transition: 0.2s; }
    .navigation a:hover, .navigation a:focus {
      color: #333; }
    @media screen and (min-width: 40em) {
      .navigation a {
        font-size: 1rem; } }

.more-link {
  position: relative;
  margin-left: 0.25rem !important; }
  @media screen and (min-width: 50em) {
    .more-link {
      display: none; } }
  .more-link > a {
    z-index: 2;
    position: relative;
    background-color: transparent;
    border-radius: 5px 5px 0 0;
    line-height: 2;
    padding: 0 0.25rem; }
  .more-link ul {
    position: absolute;
    top: 150%;
    right: 0;
    background-color: #fff;
    padding: 1rem 1rem;
    border: solid 1px #d4b86d;
    flex-direction: column;
    z-index: 1;
    margin-top: -1px;
    opacity: 0;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
    z-index: -1;
    display: flex;
    transition: transform 0.5s, opacity 0.25s;
    transform: rotateX(-30deg) perspective(1px);
    transform-origin: 50% 0%;
    pointer-events: none; }
    .more-link ul:before {
      content: '';
      width: 0;
      height: 0;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #d4b86d transparent;
      border-style: solid;
      position: absolute;
      top: 0;
      right: 1rem;
      transform: translateY(-99%); }
    .more-link ul li {
      margin-left: 0; }
      .more-link ul li:not(:last-child) {
        margin-bottom: 0.5rem; }
      .more-link ul li a {
        padding: 0.2rem 2rem;
        display: inline-block;
        color: #979691;
        font-weight: 600; }
        .more-link ul li a:before {
          content: '/';
          color: #d4b86d;
          margin-right: 0.5rem; }
  .more-link--active > a {
    border-color: #ddd; }
  .more-link--active ul {
    opacity: 1;
    transform: none;
    pointer-events: auto; }
  .more-link li:after {
    display: none; }

.section__wrapper {
  display: flex;
  max-width: 2000px;
  margin: 0 auto;
  flex-direction: column; }
  @media screen and (min-width: 80em) {
    .section__wrapper {
      padding-top: 0rem;
      flex-direction: row; } }

.section--moderator {
  background-color: #000;
  padding-bottom: 4rem; }
  @media screen and (min-width: 50em) {
    .section--moderator {
      padding-bottom: 3rem; } }
  @media screen and (min-width: 100em) {
    .section--moderator {
      padding-bottom: 10rem; } }
  .section--moderator .section__wrapper {
    align-items: center; }
  .section--moderator h2 {
    color: #fff;
    position: relative;
    padding-bottom: 0.75rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    transition: opacity 0.5s; }
    @media screen and (min-width: 40em) {
      .section--moderator h2 {
        font-size: 2rem; } }
    .section--moderator h2:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 200px;
      height: 1px;
      background-color: #d4b86d;
      transition: 0.25s;
      transform-origin: 0 0;
      transition: 0.5s 0.2s; }
    .section--moderator h2.parallax:after {
      transform: scale(0, 1); }
    .section--moderator h2.parallax--active:after {
      transform: scale(1, 1); }
  .section--moderator .section__image {
    position: relative;
    flex: 1; }
    .section--moderator .section__image img {
      max-width: 100%; }
    .section--moderator .section__image:after {
      content: '';
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, black);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      @media screen and (min-width: 50em) {
        .section--moderator .section__image:after {
          background: linear-gradient(90deg, rgba(0, 0, 0, 0) 10%, black); } }
    .section--moderator .section__image__info {
      font-family: "Titillium Web", Arial, sans-serif;
      text-transform: uppercase;
      position: absolute;
      bottom: 2rem;
      left: 3rem;
      right: 3rem;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .section--moderator .section__image__info__name {
        color: #d4b86d;
        margin-bottom: 0;
        font-size: 1.1rem;
        max-width: 22rem;
        width: 100%; }
      .section--moderator .section__image__info__description {
        color: #979691;
        margin-bottom: 0;
        font-size: 0.9rem;
        width: 100%;
        max-width: 22rem; }
      @media screen and (min-width: 40em) {
        .section--moderator .section__image__info {
          bottom: 7rem; }
          .section--moderator .section__image__info__name {
            font-size: 1.6rem; } }
  .section--moderator .section__text {
    flex: 1;
    color: #fff;
    padding: 0 2rem;
    line-height: 1.4; }
    .section--moderator .section__text a {
      color: #d4b86d;
      text-decoration: none;
      border-bottom: solid 1px transparent; }
      .section--moderator .section__text a:hover {
        color: #cbb671;
        border-bottom-color: #d4b86d; }
    @media screen and (min-width: 40em) {
      .section--moderator .section__text {
        padding: 0 4rem 4rem;
        padding-top: 4em; } }
  .section--moderator .section__text__content {
    -moz-column-count: 1;
         column-count: 1;
    font-size: 1.3rem; }
    @media screen and (min-width: 50em) {
      .section--moderator .section__text__content {
        -moz-column-count: 2;
             column-count: 2; } }
    @media screen and (min-width: 95em) {
      .section--moderator .section__text__content {
        -moz-column-count: 2;
             column-count: 2;
        font-size: 1.65rem; } }
    .event-ended .section--moderator .section__text__content {
      -moz-column-count: 1;
           column-count: 1;
      width: 50%; }
    .section--moderator .section__text__content.parallax {
      transform: perspective(1px) translateY(10%);
      opacity: 0;
      transition: 0.5s;
      will-change: transform, opacity; }
      .section--moderator .section__text__content.parallax--active {
        opacity: 1;
        transform: perspective(1px) translateY(0); }

.moderator-buttons {
  padding-top: 2rem; }
  .moderator-buttons > button {
    margin-right: 1em; }

.section--text-columns {
  color: #000;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 0 1rem; }
  .section--text-columns .section__wrapper {
    max-width: 1500px; }
  .section--text-columns h2 {
    font-size: 1.5rem;
    position: relative;
    padding-bottom: 0.75rem; }
    .section--text-columns h2:after {
      content: '';
      position: absolute;
      height: 1px;
      background-color: #d4b86d;
      left: 0;
      bottom: 0;
      width: 8rem; }

.text-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 1.3rem; }
  .text-column a {
    color: #d4b86d;
    text-decoration: none;
    font-family: "Titillium Web", Arial, sans-serif; }
    .text-column a:hover {
      text-decoration: underline; }
  .text-column img {
    height: auto;
    max-width: 100%; }
  .text-column .box-symbol {
    text-align: center;
    margin-bottom: 1rem; }
    .text-column .box-symbol img {
      max-width: 50%; }
  @media screen and (min-width: 40em) {
    .text-column {
      flex-direction: row; } }

.text-column--vertical {
  flex-direction: column;
  flex: inherit;
  margin-bottom: 3rem; }
  @media screen and (min-width: 40em) {
    .text-column--vertical {
      flex: 1; } }

.text-column__inner {
  flex: 1;
  padding: 0 1rem; }
  .text-column__inner--venue {
    order: 1; }
  @media screen and (min-width: 50em) {
    .text-column__inner {
      padding: 0 2rem; }
      .text-column__inner--venue {
        order: 2;
        display: block; } }

.text-column__quote {
  color: #d4b86d;
  text-align: center;
  display: block;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0; }
  .text-column__quote p {
    position: relative;
    margin-bottom: 1rem;
    margin-top: 0;
    font-size: 2rem;
    font-family: "Bevan", sans-serif; }
  .text-column__quote p:before {
    content: "\201C"; }
  .text-column__quote p:after {
    content: "\201D"; }
  .text-column__quote footer:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 40%;
    height: 1px;
    background-color: #d4b86d; }
  .text-column__quote footer {
    color: #000;
    position: relative;
    padding-top: 1rem;
    font-family: "Titillium Web", Arial, sans-serif; }

.text-column__inner--quote {
  margin-top: 2em;
  order: 2;
  padding: 0; }
  @media screen and (min-width: 50em) {
    .text-column__inner--quote {
      margin-top: 0;
      order: 1; } }

.text-column--image {
  flex: inherit;
  display: block;
  margin-top: 2rem; }

.text-column__inner--image {
  padding: 0;
  position: relative; }
  .text-column__inner--image img {
    vertical-align: top; }
  .text-column__inner--image:after {
    content: '';
    position: absolute;
    top: 1rem;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    background-color: transparent;
    border: solid 1px #fff; }

.footer {
  border-top: 1.5rem solid #02579a; }
  .footer.footer--mod-wwt {
    border-top: 1.5rem solid #025835; }

footer[role="contentinfo"] {
  padding: 1rem 1rem 2rem;
  background-color: #5d5d5d;
  color: #fff;
  font-size: 1.2rem; }
  footer[role="contentinfo"] .section__wrapper {
    max-width: 1500px;
    padding: 0 0rem; }
    @media screen and (min-width: 50em) {
      footer[role="contentinfo"] .section__wrapper {
        padding: 0 2rem; } }
  footer[role="contentinfo"] .section {
    max-width: 500px;
    padding-right: 3rem; }
    footer[role="contentinfo"] .section:last-child {
      padding-right: 0; }
    footer[role="contentinfo"] .section:first-child {
      display: none; }
      @media screen and (min-width: 50em) {
        footer[role="contentinfo"] .section:first-child {
          display: block; } }
  footer[role="contentinfo"] h4, footer[role="contentinfo"] h5 {
    font-family: "Titillium Web", Arial, sans-serif;
    color: #d4b86d;
    margin: 0 0 0.25rem; }
  footer[role="contentinfo"] h5 {
    font-size: 1rem;
    margin-top: 1.5rem; }
  @media screen and (min-width: 40em) {
    footer[role="contentinfo"] {
      padding: 2rem 3rem 3rem; } }

.link {
  font-size: 0.9rem; }
  .link a {
    color: #d4b86d;
    font-family: "Titillium Web", Arial, sans-serif;
    text-decoration: none; }

.section--contact p {
  margin: 0;
  font-family: "Titillium Web", Arial, sans-serif; }

.section--contact .title {
  text-transform: uppercase;
  font-size: 0.8rem; }

.section--contact {
  margin-bottom: 1.5rem; }

.section--newsletter p {
  margin-bottom: 0; }

.section--newsletter .link button {
  font-size: 1rem;
  color: #d4b86d;
  font-family: "Titillium Web", Arial, sans-serif;
  text-decoration: none;
  padding: 0;
  bottom: 0;
  cursor: pointer; }

.section--newsletter {
  margin-bottom: 1.5rem; }

.sponsor-home-section {
  height: auto;
  padding-bottom: 2rem; }

.sponsor-home-section__content {
  max-width: 1500px;
  width: 100%;
  margin: 1.5rem auto;
  position: relative; }

.sponsor-home-section__header {
  display: flex;
  justify-content: center; }

.sponsor-home-section__header h2 {
  width: auto;
  position: relative;
  padding-bottom: 0.75rem;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  transition: opacity 0.5s; }

.sponsor-home-section__header h2:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 200px;
  height: 1px;
  background-color: #d4b86d;
  transition: 0.25s;
  transform: translateX(-50%);
  transform-origin: 50% 0;
  transition: 0.5s 0.2s; }

.sponsor-home-section__header h2.parallax:after {
  transform: scale(0, 1) translateX(-50%); }

.sponsor-home-section__header h2.parallax--active:after {
  transform: scale(1, 1) translateX(-50%); }

.sponsor-home__main-sponsors {
  padding: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.sponsor-home__main-sponsor {
  min-width: calc(25% - 1em);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 3rem;
  padding-top: 4rem;
  margin-right: 2rem;
  margin-left: 2rem;
  margin-bottom: 2rem; }
  .sponsor-home__main-sponsor:nth-of-type(4n) {
    margin-right: 0; }

.sponsor__border {
  position: absolute;
  background-color: #d4b86d; }
  .sponsor__border--top, .sponsor__border--bottom {
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    transform: scale(0, 1);
    opacity: 0; }
  .sponsor__border--right, .sponsor__border--left {
    right: 0;
    top: 0;
    height: 100%;
    width: 1px;
    transform: scale(1, 0);
    transform-origin: 0 0; }
  .sponsor__border--bottom {
    top: auto;
    bottom: 0; }
  .sponsor__border--left {
    right: auto;
    left: 0; }

.sponsor-home__main-sponsor:hover .sponsor__border--top, .sponsor-home__main-sponsor:hover .sponsor__border--bottom {
  transform: scale(1, 1);
  transition: 0.5s 0.3s;
  opacity: 1; }

.sponsor-home__main-sponsor:hover .sponsor__border--right, .sponsor-home__main-sponsor:hover .sponsor__border--left {
  transform: scale(1, 1);
  transform-origin: 0 0;
  transition: 1s 0.8s; }

.sponsor-home__main-sponsor__label {
  position: absolute;
  top: -1rem;
  lefT: 50%;
  height: 3rem;
  min-width: 9em;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
  text-align: center;
  font-family: "Titillium Web", Arial, sans-serif;
  color: #d4b86d;
  font-weight: 400;
  text-decoration: none;
  font-size: 1.2rem;
  z-index: 2;
  transform: translateX(-50%);
  background-color: #fff; }

.sponsor-home__main-sponsor__image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 25em;
  align-self: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
  margin-left: auto;
  margin-right: auto; }

.sponsor-home__main-sponsor__image img {
  position: relative;
  z-index: 1;
  max-width: 100%; }

.sponsor-home__main-sponsor__name {
  opacity: 0;
  width: 100%;
  text-align: center;
  display: none; }
  .sponsor-home__main-sponsor__name h4 {
    font-family: "Cormorant Garamond", serif;
    color: #5d5d5d;
    font-size: 1.5rem; }

.sponsor-home__silver-sponsors {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 2rem; }

.sponsor-home__silver-sponsor {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  position: relative;
  transition: -webkit-filter 0.3s ease-in-out;
  transition: filter 0.3s ease-in-out;
  transition: filter 0.3s ease-in-out, -webkit-filter 0.3s ease-in-out;
  min-width: calc(20% - 3rem);
  margin-right: 1.5rem;
  margin-left: 1.5rem; }
  .sponsor-home__silver-sponsor:nth-of-type(4n) {
    margin-right: 0; }
  .sponsor-home__silver-sponsor .sponsor__border {
    background-color: #979691; }
  .sponsor-home__silver-sponsor .sponsor-home__silver-sponsor__label {
    position: absolute;
    top: -1em;
    lefT: 50%;
    height: 1.5rem;
    width: 10rem;
    padding-left: 0.5em;
    padding-right: 0.5em;
    text-align: center;
    color: #979691;
    font-family: "Titillium Web", Arial, sans-serif;
    background-color: #fff;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    transform: translateX(-50%); }
  .sponsor-home__silver-sponsor .sponsor-home__silver-sponsor__name {
    opacity: 0;
    width: 100%;
    text-align: center;
    display: none; }
    .sponsor-home__silver-sponsor .sponsor-home__silver-sponsor__name h4 {
      font-family: "Cormorant Garamond", serif;
      color: #5d5d5d; }
  .sponsor-home__silver-sponsor:hover .sponsor-home__silver-sponsor__label {
    opacity: 1; }
  .sponsor-home__silver-sponsor:hover .sponsor-home__silver-sponsor__name {
    opacity: 0; }
  .sponsor-home__silver-sponsor .sponsor-home__silver-sponsor__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 12em;
    align-self: flex-start;
    flex-grow: 1;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: auto; }
  .sponsor-home__silver-sponsor .sponsor-home__silver-sponsor__logo img {
    position: relative;
    max-width: 100%;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%); }

.sponsor-home__silver-sponsor:hover .sponsor__border--top, .sponsor-home__silver-sponsor:hover .sponsor__border--bottom {
  transform: scale(1, 1);
  transition: 0.5s 0.3s;
  opacity: 1; }

.sponsor-home__silver-sponsor:hover .sponsor__border--right, .sponsor-home__silver-sponsor:hover .sponsor__border--left {
  transform: scale(1, 1);
  transform-origin: 0 0;
  transition: 1s 0.8s; }

.sponsor-home__friend-sponsor {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  position: relative;
  transition: -webkit-filter 0.3s ease-in-out;
  transition: filter 0.3s ease-in-out;
  transition: filter 0.3s ease-in-out, -webkit-filter 0.3s ease-in-out;
  min-width: calc(20% - 3rem);
  margin-right: 1.5rem;
  margin-left: 1.5rem; }
  .sponsor-home__friend-sponsor:nth-of-type(4n) {
    margin-right: 0; }
  .sponsor-home__friend-sponsor .sponsor__border {
    background-color: #979691; }
  .sponsor-home__friend-sponsor .sponsor-home__friend-sponsor__label {
    position: absolute;
    top: -1em;
    lefT: 50%;
    height: 1.5rem;
    width: 10rem;
    padding-left: 0.5em;
    padding-right: 0.5em;
    text-align: center;
    color: #979691;
    font-family: "Titillium Web", Arial, sans-serif;
    background-color: #fff;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    transform: translateX(-50%); }
  .sponsor-home__friend-sponsor .sponsor-home__friend-sponsor__name {
    opacity: 0;
    width: 100%;
    text-align: center;
    display: none; }
    .sponsor-home__friend-sponsor .sponsor-home__friend-sponsor__name h4 {
      font-family: "Cormorant Garamond", serif;
      color: #5d5d5d; }
  .sponsor-home__friend-sponsor:hover .sponsor-home__friend-sponsor__label {
    opacity: 1; }
  .sponsor-home__friend-sponsor:hover .sponsor-home__friend-sponsor__name {
    opacity: 0; }
  .sponsor-home__friend-sponsor .sponsor-home__friend-sponsor__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 12em;
    align-self: flex-start;
    flex-grow: 1;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: auto; }
  .sponsor-home__friend-sponsor .sponsor-home__friend-sponsor__logo img {
    position: relative;
    max-width: 100%;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%); }

.sponsor-home__friend-sponsor:hover .sponsor__border--top, .sponsor-home__friend-sponsor:hover .sponsor__border--bottom {
  transform: scale(1, 1);
  transition: 0.5s 0.3s;
  opacity: 1; }

.sponsor-home__friend-sponsor:hover .sponsor__border--right, .sponsor-home__friend-sponsor:hover .sponsor__border--left {
  transform: scale(1, 1);
  transform-origin: 0 0;
  transition: 1s 0.8s; }

@media screen and (max-width: 60em) {
  .sponsor-home__main-sponsor {
    width: calc(50% - 1em);
    margin-right: 1em;
    padding: 1em;
    padding-top: 3em; }
  .sponsor-home__main-sponsor__image {
    max-width: 100%; }
  .sponsor-home__silver-sponsor {
    width: calc(40% - 1em);
    margin-right: 1em;
    padding: 1em;
    padding-top: 2em; }
  .sponsor-home__silver-sponsor__logo {
    max-width: 75%; }
  .sponsor-home__friend-sponsor {
    width: calc(40% - 1em);
    margin-right: 1em;
    padding: 1em;
    padding-top: 2em; }
  .sponsor-home__friend-sponsor__logo {
    max-width: 75%; } }

@media screen and (max-width: 40em) {
  .sponsor-home__main-sponsors {
    padding: 1em; } }

.sponsors {
  padding-bottom: 2rem; }

.gold-sponsors {
  display: block;
  width: 1900px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  margin-top: 3rem; }

.gold-sponsor {
  margin-top: 6em;
  display: flex;
  width: 100%;
  height: auto;
  background: #fff;
  position: relative;
  border: 1px solid #d4b86d;
  padding: 3rem; }
  .gold-sponsor .gold-sponsor__label {
    position: absolute;
    top: -1.5rem;
    left: 50%;
    height: 3rem;
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
    text-align: center;
    font-family: "Titillium Web", Arial, sans-serif;
    color: #d4b86d;
    font-weight: 400;
    text-decoration: none;
    font-size: 2rem;
    z-index: 2;
    transform: translateX(-50%);
    background-color: #fff; }
  .gold-sponsor .gold-sponsor__logo {
    width: 22rem;
    margin-right: 3rem;
    flex-grow: 0;
    flex-shrink: 0; }
    .gold-sponsor .gold-sponsor__logo img {
      width: 100%; }
  .gold-sponsor .gold-sponsor__content {
    display: block; }
  .gold-sponsor .gold-sponsor__name h3 {
    font-family: "Titillium Web", Arial, sans-serif;
    color: #d4b86d;
    font-size: 2em;
    margin-top: 0;
    line-height: 1.2; }
  .gold-sponsor p {
    font-family: "Cormorant Garamond", serif;
    font-size: 1.5em;
    max-width: 960px;
    width: 100%; }

.silver-sponsors {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 1em;
  grid-column-gap: 1.5em;
  -moz-column-gap: 1.5em;
       column-gap: 1.5em;
  width: 1900px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  margin-top: 6rem;
  padding-bottom: 4rem; }

.silver-sponsor {
  display: flex;
  min-height: 20em;
  background: white;
  border: 1px solid #979691;
  margin-right: 2em;
  padding: 2em;
  position: relative;
  align-items: flex-start; }
  .silver-sponsor .silver-sponsor__logo {
    width: 10rem;
    margin-right: 2rem;
    flex-grow: 0;
    flex-shrink: 0; }
    .silver-sponsor .silver-sponsor__logo img {
      width: 100%; }
  .silver-sponsor .silver-sponsor__content {
    display: block; }
  .silver-sponsor .silver-sponsor__name h3 {
    font-family: "Titillium Web", Arial, sans-serif;
    color: #979691;
    font-size: 2em;
    margin-top: 0;
    line-height: 1.2; }
  .silver-sponsor .silver-sponsor__label {
    position: absolute;
    top: -0.75em;
    left: 50%;
    height: 1.5rem;
    width: auto;
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
    color: #979691;
    font-family: "Titillium Web", Arial, sans-serif;
    background-color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
    text-decoration: none;
    z-index: 2;
    transition: opacity 0.3s ease-in-out;
    transform: translateX(-50%); }
  .silver-sponsor p {
    font-family: "Cormorant Garamond", serif;
    font-size: 1.3em;
    max-width: 960px;
    width: 100%; }

.friend-sponsor {
  display: flex;
  min-height: 20em;
  background: white;
  border: 1px solid #979691;
  margin-right: 2em;
  padding: 2em;
  position: relative;
  align-items: flex-start; }
  .friend-sponsor .friend-sponsor__logo {
    width: 10rem;
    margin-right: 2rem;
    flex-grow: 0;
    flex-shrink: 0; }
    .friend-sponsor .friend-sponsor__logo img {
      width: 100%; }
  .friend-sponsor .friend-sponsor__content {
    display: block; }
  .friend-sponsor .friend-sponsor__name h3 {
    font-family: "Titillium Web", Arial, sans-serif;
    color: #979691;
    font-size: 2em;
    margin-top: 0;
    line-height: 1.2; }
  .friend-sponsor .friend-sponsor__label {
    position: absolute;
    top: -0.75em;
    left: 50%;
    height: 1.5rem;
    width: auto;
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
    color: #979691;
    font-family: "Titillium Web", Arial, sans-serif;
    background-color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
    text-decoration: none;
    z-index: 2;
    transition: opacity 0.3s ease-in-out;
    transform: translateX(-50%); }
  .friend-sponsor p {
    font-family: "Cormorant Garamond", serif;
    font-size: 1.3em;
    max-width: 960px;
    width: 100%; }

.sponsors-contact {
  margin-top: 6em; }
  .sponsors-contact p {
    font-size: 1.5rem;
    color: #000; }

@media screen and (max-width: 80em) {
  .silver-sponsors {
    display: block; }
  .silver-sponsor, .friend-sponsor {
    margin-bottom: 4em;
    margin-right: 0; } }

@media screen and (max-width: 60em) {
  .main-sponsors {
    padding-left: 1rem;
    padding-right: 1rem; }
  .gold-sponsors {
    padding-left: 1rem;
    padding-right: 1rem; }
  .gold-sponsor {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem; }
    .gold-sponsor .gold-sponsor__label {
      font-size: 1.5rem;
      width: 15rem;
      top: -1.25rem; }
    .gold-sponsor .gold-sponsor__logo {
      margin-bottom: 2em;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      padding-left: 1rem;
      padding-right: 1rem; }
    .gold-sponsor .gold-sponsor__content {
      margin-left: auto;
      margin-right: auto; }
    .gold-sponsor .gold-sponsor__name h3 {
      font-size: 1.5em; }
  .silver-sponsors {
    padding-left: 1rem;
    padding-right: 1rem; }
  .silver-sponsor {
    padding-left: 1rem;
    padding-right: 1rem;
    display: block; }
    .silver-sponsor .silver-sponsor__label {
      font-size: 1.5rem;
      width: 15rem;
      top: -1.25rem; }
    .silver-sponsor .silver-sponsor__logo {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2em;
      padding-left: 1rem;
      padding-right: 1rem; }
    .silver-sponsor .silver-sponsor__name h3 {
      font-size: 1.5em; }
  .friend-sponsor {
    padding-left: 1rem;
    padding-right: 1rem;
    display: block; }
    .friend-sponsor .friend-sponsor__label {
      font-size: 1.5rem;
      width: 15rem;
      top: -1.25rem; }
    .friend-sponsor .friend-sponsor__logo {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2em;
      padding-left: 1rem;
      padding-right: 1rem; }
    .friend-sponsor .friend-sponsor__name h3 {
      font-size: 1.5em; } }

.sponsors {
  padding-bottom: 2rem; }

.archived-events {
  display: block;
  width: 1900px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  margin-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem; }

.archived-events-missing {
  display: block;
  width: 1900px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  margin-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem; }

.archived-event {
  margin-top: 6em;
  display: flex;
  width: 100%;
  height: auto;
  background: #fff;
  position: relative;
  border: 1px solid #d4b86d;
  padding: 1_rem;
  min-height: 18rem; }
  .archived-event__label {
    position: absolute;
    top: -1.5rem;
    left: 50%;
    height: 3rem;
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
    text-align: center;
    font-family: "Titillium Web", Arial, sans-serif;
    color: #d4b86d;
    font-weight: 400;
    text-decoration: none;
    font-size: 2rem;
    z-index: 2;
    transform: translateX(-50%);
    background-color: #fff; }
  .archived-event__image {
    width: 26rem;
    margin-right: 3rem;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 18rem;
    position: relative;
    overflow: hidden; }
    .archived-event__image img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%; }
  .archived-event__content {
    display: block;
    padding-left: 2rem;
    padding-bottom: 2rem; }
    .archived-event__content__name h3 {
      font-family: "Titillium Web", Arial, sans-serif;
      color: #d4b86d;
      font-size: 2em;
      margin-top: 0;
      line-height: 1.2; }
    .archived-event__content p {
      font-family: "Cormorant Garamond", serif;
      font-size: 1.5em;
      max-width: 960px;
      width: 100%; }

@media screen and (max-width: 80em) {
  .archived-events {
    padding-left: 1rem;
    padding-right: 1rem; }
  .archived-event {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .archived-event__label {
      font-size: 1.5rem;
      width: auto;
      top: -1.4rem;
      height: 2rem;
      white-space: nowrap; } }

@media screen and (max-width: 50em) {
  .archived-events {
    padding-left: 1rem;
    padding-right: 1rem; }
  .archived-event {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem; }
    .archived-event__label {
      font-size: 1.2rem;
      top: -1.2rem; }
    .archived-event__image {
      margin-bottom: 0em;
      margin-left: none;
      margin-right: none;
      max-width: 100%;
      padding-left: 1rem;
      padding-right: 1rem; }
    .archived-event__content {
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      padding-right: 0; }
      .archived-event__content .archived-event__name h3 {
        font-size: 1.5em; } }

.section--speakers {
  padding: 2rem; }

.speakerlist__empty {
  font-family: Titillium Web,Arial,sans-serif;
  text-align: center;
  font-size: 2em;
  padding-top: 4rem;
  padding-bottom: 4rem; }

.speakerlist {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: flex-start;
  max-width: 1500px; }
  @media screen and (min-width: 50em) {
    .speakerlist {
      padding: 0 2rem;
      margin: 0 -2rem -2rem 0; } }

.speaker {
  flex: 1 1 100%;
  margin: 1rem 0;
  position: relative;
  overflow: hidden;
  /*cursor: pointer;*/
  opacity: 1; }
  @media screen and (min-width: 40em) {
    .speaker {
      margin: 0 2% 2% 0;
      flex: 0 1 48%; } }
  @media screen and (min-width: 70em) {
    .speaker {
      flex: 0 1 31%; } }
  @media screen and (min-width: 90em) {
    .speaker {
      flex: 0 1 23%; } }

.speaker__link {
  display: block;
  cursor: pointer; }

.speaker__image {
  width: 100%;
  text-align: center;
  position: relative; }
  .speaker__image img {
    width: 100%;
    height: auto;
    vertical-align: top;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%); }
  .speaker--info .speaker__image {
    opacity: 0.2; }
  .speaker__image:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #cbb671;
    mix-blend-mode: multiply; }

.speaker__meta {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.55));
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transform: translateY(0%);
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  opacity: 1;
  padding: 0 1rem;
  font-size: 1.2rem;
  pointer-events: none; }
  @media screen and (min-width: 40em) {
    .speaker__meta {
      /*bottom: 2rem;
    right: 2rem;*/ } }
  .speaker__meta h4 {
    font-family: "Bevan", sans-serif;
    font-size: 1.8rem;
    color: #fff;
    text-transform: uppercase;
    margin-top: 1.5rem;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.3;
    position: relative;
    text-align: left;
    margin-bottom: 0; }
  .speaker__meta p.speaker__place {
    position: relative;
    color: #d4b86d;
    font-family: "Cormorant Garamond", serif;
    font-style: italic;
    font-size: 1.3rem;
    font-weight: 500;
    padding-bottom: 0.6rem;
    margin-bottom: 0.4rem;
    margin-top: 0;
    line-height: 1; }
    .speaker__meta p.speaker__place:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 40%;
      height: 1px;
      background-color: #d4b86d; }
      .event-ended .speaker__meta p.speaker__place:after {
        display: none; }
  .speaker__meta p {
    margin: 0;
    text-align: left;
    margin-bottom: 0.5rem; }
    .speaker__meta p:last-child {
      margin-bottom: 1.5rem; }
  .speaker__meta a {
    color: #fff;
    text-decoration: none;
    font-family: "Titillium Web", Arial, sans-serif;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold; }
  @media screen and (min-width: 50em) {
    .speaker__meta {
      transform: translateY(10%);
      opacity: 0; } }
  .event-ended .speaker__meta {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto; }
    .event-ended .speaker__meta h4 {
      font-size: 1.4rem; }
    .event-ended .speaker__meta .speaker__place {
      font-size: 1.2rem; }
  .speaker:hover .speaker__meta, .speaker:focus .speaker__meta {
    transform: translateY(0);
    opacity: 1; }

.speaker--info {
  color: #fff; }
  .speaker--info .speaker__meta {
    background: #d4b86d;
    transform: translateY(0);
    opacity: 1;
    justify-content: center;
    font-size: 1.2rem;
    padding: 0 1rem; }
    @media screen and (min-width: 50em) {
      .speaker--info .speaker__meta {
        font-size: 1.3rem; } }
    @media screen and (min-width: 100em) {
      .speaker--info .speaker__meta {
        padding: 0 2rem; } }
    .speaker--info .speaker__meta h4 {
      font-family: "Titillium Web", Arial, sans-serif;
      color: #fff;
      padding-bottom: 0;
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.1;
      margin-bottom: 0.5rem; }

.speaker--coming-soon .speaker__meta {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  transform: translateY(0);
  opacity: 1;
  justify-content: center;
  align-items: center; }
  .around--large .speaker--coming-soon .speaker__meta {
    background: none; }
  .speaker--coming-soon .speaker__meta h4 {
    text-align: center; }
  .speaker--coming-soon .speaker__meta h4:after {
    margin: 0 auto; }

.speaker--coming-soon .speaker__image:after {
  display: none; }

.speaker--blue {
  cursor: pointer; }
  .speaker--blue .speaker__meta {
    background-color: #00629a;
    text-align: center; }
    .speaker--blue .speaker__meta p {
      text-transform: uppercase;
      font-family: "Titillium Web", Arial, sans-serif;
      font-weight: 400;
      text-align: center;
      max-width: 60%;
      font-size: 1.3rem;
      margin: 0 auto;
      line-height: 1.2; }
    .speaker--blue .speaker__meta h4 {
      margin-top: 0; }
    .speaker--blue .speaker__meta h4:after {
      display: none; }
  .speaker--blue .speaker__meta__inner {
    margin: 0.75rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: solid 1px #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }

.speaker--large h3 {
  color: #d4b86d;
  font-family: "Titillium Web", Arial, sans-serif;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
  font-weight: 600; }

.speaker__title {
  color: #d4b86d;
  font-family: "Titillium Web", Arial, sans-serif;
  margin: 0.2rem 0 1rem;
  line-height: 1.4;
  font-size: 1.1rem;
  font-weight: 300; }
  .speaker__title p {
    margin: 0; }

.speaker__description {
  color: #333; }
  .speaker__description p {
    margin-bottom: 0.5rem;
    font-size: 1.1rem; }

.speaker--multiple h4 {
  font-size: 1.5rem;
  line-height: 1.5; }

.speaker__event {
  position: relative;
  padding-top: 1rem;
  margin-top: 1rem;
  font-family: "Titillium Web", Arial, sans-serif;
  color: #d4b86d;
  line-height: 1.7;
  font-size: 1.2rem; }
  .speaker__event__date {
    text-transform: uppercase;
    font-weight: bold; }
  .speaker__event a, .speaker__event__session {
    color: #979691;
    text-transform: uppercase;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    font-weight: 400;
    font-size: 1.1rem; }
    .speaker__event a:before, .speaker__event__session:before {
      content: '/';
      color: #d4b86d;
      margin: 0 0.5rem;
      font-weight: normal;
      text-decoration: none;
      display: inline-block;
      font-size: 1.2rem; }
  .speaker__event a:hover {
    text-decoration: underline; }
    .speaker__event a:hover:before {
      text-decoration: none; }
  .speaker__event__session {
    color: #d4b86d;
    font-weight: bold;
    font-size: inherit; }
    .speaker__event__session:before {
      font-weight: bold;
      font-size: inherit; }
  .speaker__event:before {
    content: '';
    width: 40%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #d4b86d; }

.speaker__buttons {
  display: flex;
  margin-bottom: 1.5rem; }
  .speaker__buttons button.video-button {
    flex: 1;
    margin-right: 1rem;
    padding: 0.3rem 1rem;
    font-size: 0.8rem; }

.post {
  width: 900px;
  max-width: 100%;
  margin: 0rem auto 6rem;
  padding: 0 1rem; }
  .page-template-page-about .post {
    margin: 0 auto 4rem; }
    .page-template-page-about .post .post__author {
      margin-top: 3rem; }
  .post:last-child {
    margin-top: 0;
    margin-bottom: 10rem; }
  .post h1 {
    font-size: 2rem; }
  .post a {
    color: #d4b86d;
    text-decoration: none;
    font-family: "Titillium Web", Arial, sans-serif; }
    .post a:hover {
      text-decoration: underline; }
  .post h2 {
    font-size: 1.5rem;
    font-family: "Titillium Web", Arial, sans-serif;
    font-weight: 600;
    position: relative;
    padding-bottom: 0.75rem; }
    .post h2 a:hover {
      text-decoration: none; }
    .post h2 a {
      font-weight: 400; }
    .post h2:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 200px;
      height: 1px;
      background-color: #d4b86d;
      transition: 0.25s; }

.post__content h3, .venue__content h3, .tickets-main h3, .ticket-row__post h3, .wwt-about__text h3, .archive-summary__content h3, .archive-gallery__content h3 {
  font-family: "Titillium Web", Arial, sans-serif;
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #222; }

.post__content a, .venue__content a, .tickets-main a, .ticket-row__post a, .wwt-about__text a, .archive-summary__content a, .archive-gallery__content a {
  color: #d4b86d;
  text-decoration: none;
  font-family: "Titillium Web", Arial, sans-serif; }
  .post__content a:hover, .venue__content a:hover, .tickets-main a:hover, .ticket-row__post a:hover, .wwt-about__text a:hover, .archive-summary__content a:hover, .archive-gallery__content a:hover {
    text-decoration: underline; }

.post__content p, .venue__content p, .tickets-main p, .ticket-row__post p, .wwt-about__text p, .archive-summary__content p, .archive-gallery__content p {
  font-size: 1.3rem;
  color: #345; }
  @media screen and (min-width: 50em) {
    .post__content p, .venue__content p, .tickets-main p, .ticket-row__post p, .wwt-about__text p, .archive-summary__content p, .archive-gallery__content p {
      font-size: 1.4rem; } }

.post__content--has-preamble p:first-of-type {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: "Titillium Web", Arial, sans-serif; }
  @media screen and (min-width: 50em) {
    .post__content--has-preamble p:first-of-type {
      font-size: 1.6rem; } }

.faq {
  padding: 1rem 0;
  position: relative; }
  .faq:after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #d4b86d; }
  .faq h3 {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    .faq h3 .faq__heading {
      flex: 1; }
  .faq p {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0; }
  .faq--active p {
    max-height: 500px; }
  @media screen and (min-width: 50em) {
    .faq {
      border-bottom: none; }
      .faq:after {
        display: none; }
      .faq p {
        max-height: inherit; } }

.faq__close {
  position: relative;
  width: 2rem;
  height: 2rem;
  background-color: #d4b86d;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 1rem;
  transition: transform 0.15s ease-in-out; }
  .faq--active .faq__close {
    transform: scale(1.2); }
  .faq__close:before, .faq__close:after {
    content: '';
    background-color: #fff;
    position: absolute;
    width: 2px;
    height: 30%;
    top: 50%;
    left: 50%;
    transition: 0.2s;
    transform-origin: 30% 50%; }
    .faq--active .faq__close:before, .faq--active .faq__close:after {
      transform-origin: 0 0; }
  .faq__close:before {
    transform: rotate(45deg) translate(-50%, -50%); }
    .faq--active .faq__close:before {
      transform: rotate(45deg) translate(-50%, -50%);
      transform-origin: 0 0; }
  .faq__close:after {
    transform: rotate(-45deg) translate(-50%, -50%); }
    .faq--active .faq__close:after {
      transform: rotate(-45deg) translate(-50%, -50%);
      transform-origin: 0 0; }
  @media screen and (min-width: 50em) {
    .faq__close {
      display: none; } }

.section--schedule {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0rem 1rem 4rem; }
  @media screen and (min-width: 50em) {
    .section--schedule {
      padding: 4rem; } }

.schedule {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 50em) {
    .schedule {
      flex-direction: row; } }

.schedule__day {
  flex: 1; }
  @media screen and (min-width: 50em) {
    .schedule__day {
      margin-right: 6rem; }
      .schedule__day:last-child {
        margin-right: 0; } }
  .schedule__day.parallax--active:nth-child(1) .schedule__item {
    transition: 0.5s;
    transform: translateY(0%);
    opacity: 1; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(0) {
      transition-delay: 0.2s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(1) {
      transition-delay: 0.3s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(2) {
      transition-delay: 0.4s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(3) {
      transition-delay: 0.5s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(4) {
      transition-delay: 0.6s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(5) {
      transition-delay: 0.7s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(6) {
      transition-delay: 0.8s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(7) {
      transition-delay: 0.9s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(8) {
      transition-delay: 1s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(9) {
      transition-delay: 1.1s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(10) {
      transition-delay: 1.2s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(11) {
      transition-delay: 1.3s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(12) {
      transition-delay: 1.4s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(13) {
      transition-delay: 1.5s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(14) {
      transition-delay: 1.6s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(15) {
      transition-delay: 1.7s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(16) {
      transition-delay: 1.8s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(17) {
      transition-delay: 1.9s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(18) {
      transition-delay: 2s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(19) {
      transition-delay: 2.1s; }
    .schedule__day.parallax--active:nth-child(1) .schedule__item:nth-child(20) {
      transition-delay: 2.2s; }
  .schedule__day.parallax--active:nth-child(2) .schedule__item {
    transition: 0.5s;
    transform: translateY(0%);
    opacity: 1; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(0) {
      transition-delay: 0.4s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(1) {
      transition-delay: 0.5s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(2) {
      transition-delay: 0.6s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(3) {
      transition-delay: 0.7s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(4) {
      transition-delay: 0.8s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(5) {
      transition-delay: 0.9s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(6) {
      transition-delay: 1s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(7) {
      transition-delay: 1.1s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(8) {
      transition-delay: 1.2s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(9) {
      transition-delay: 1.3s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(10) {
      transition-delay: 1.4s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(11) {
      transition-delay: 1.5s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(12) {
      transition-delay: 1.6s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(13) {
      transition-delay: 1.7s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(14) {
      transition-delay: 1.8s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(15) {
      transition-delay: 1.9s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(16) {
      transition-delay: 2s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(17) {
      transition-delay: 2.1s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(18) {
      transition-delay: 2.2s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(19) {
      transition-delay: 2.3s; }
    .schedule__day.parallax--active:nth-child(2) .schedule__item:nth-child(20) {
      transition-delay: 2.4s; }
  .schedule__day.parallax--active:nth-child(3) .schedule__item {
    transition: 0.5s;
    transform: translateY(0%);
    opacity: 1; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(0) {
      transition-delay: 0.6s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(1) {
      transition-delay: 0.7s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(2) {
      transition-delay: 0.8s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(3) {
      transition-delay: 0.9s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(4) {
      transition-delay: 1s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(5) {
      transition-delay: 1.1s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(6) {
      transition-delay: 1.2s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(7) {
      transition-delay: 1.3s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(8) {
      transition-delay: 1.4s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(9) {
      transition-delay: 1.5s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(10) {
      transition-delay: 1.6s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(11) {
      transition-delay: 1.7s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(12) {
      transition-delay: 1.8s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(13) {
      transition-delay: 1.9s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(14) {
      transition-delay: 2s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(15) {
      transition-delay: 2.1s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(16) {
      transition-delay: 2.2s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(17) {
      transition-delay: 2.3s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(18) {
      transition-delay: 2.4s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(19) {
      transition-delay: 2.5s; }
    .schedule__day.parallax--active:nth-child(3) .schedule__item:nth-child(20) {
      transition-delay: 2.6s; }
  .schedule__day.parallax--active:nth-child(4) .schedule__item {
    transition: 0.5s;
    transform: translateY(0%);
    opacity: 1; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(0) {
      transition-delay: 0.8s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(1) {
      transition-delay: 0.9s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(2) {
      transition-delay: 1s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(3) {
      transition-delay: 1.1s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(4) {
      transition-delay: 1.2s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(5) {
      transition-delay: 1.3s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(6) {
      transition-delay: 1.4s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(7) {
      transition-delay: 1.5s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(8) {
      transition-delay: 1.6s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(9) {
      transition-delay: 1.7s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(10) {
      transition-delay: 1.8s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(11) {
      transition-delay: 1.9s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(12) {
      transition-delay: 2s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(13) {
      transition-delay: 2.1s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(14) {
      transition-delay: 2.2s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(15) {
      transition-delay: 2.3s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(16) {
      transition-delay: 2.4s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(17) {
      transition-delay: 2.5s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(18) {
      transition-delay: 2.6s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(19) {
      transition-delay: 2.7s; }
    .schedule__day.parallax--active:nth-child(4) .schedule__item:nth-child(20) {
      transition-delay: 2.8s; }
  .schedule__day.parallax--active:nth-child(5) .schedule__item {
    transition: 0.5s;
    transform: translateY(0%);
    opacity: 1; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(0) {
      transition-delay: 1s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(1) {
      transition-delay: 1.1s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(2) {
      transition-delay: 1.2s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(3) {
      transition-delay: 1.3s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(4) {
      transition-delay: 1.4s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(5) {
      transition-delay: 1.5s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(6) {
      transition-delay: 1.6s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(7) {
      transition-delay: 1.7s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(8) {
      transition-delay: 1.8s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(9) {
      transition-delay: 1.9s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(10) {
      transition-delay: 2s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(11) {
      transition-delay: 2.1s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(12) {
      transition-delay: 2.2s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(13) {
      transition-delay: 2.3s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(14) {
      transition-delay: 2.4s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(15) {
      transition-delay: 2.5s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(16) {
      transition-delay: 2.6s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(17) {
      transition-delay: 2.7s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(18) {
      transition-delay: 2.8s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(19) {
      transition-delay: 2.9s; }
    .schedule__day.parallax--active:nth-child(5) .schedule__item:nth-child(20) {
      transition-delay: 3s; }

.schedule__item {
  padding: 1.25rem 1rem;
  /*border-style: solid;
  border-width: 1px 1px 0 1px;
  border-color: $color-gold;*/
  font-family: "Titillium Web", Arial, sans-serif;
  border-bottom: solid 1px #d4b86d;
  display: flex;
  align-items: center;
  opacity: 0;
  transform: translateY(-10%); }
  .schedule__item:last-child {
    border-width: 0; }

.schedule__icon {
  margin-right: 2rem;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 0 0 50px; }
  .schedule__icon img {
    border-radius: 50%;
    height: auto;
    width: 100%; }
  .schedule__icon svg {
    height: 50px;
    width: 50px; }
  .schedule__icon .schedule__icon__svg {
    background-color: #d4b86d;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .schedule__item--session .schedule__icon svg {
    width: 85%;
    height: 85%; }
  .schedule__item--session .schedule__icon svg g {
    fill: #fff; }
  .schedule__item--info .schedule__icon svg g {
    fill: rgba(0, 0, 0, 0.5); }
  .schedule__item--info .schedule__icon img {
    border-radius: 0; }

.schedule__header h3 {
  color: #d4b86d;
  font-family: "Bevan", sans-serif;
  font-family: "Titillium Web", Arial, sans-serif;
  font-weight: 400;
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2;
  text-transform: uppercase; }
  .schedule__header h3 .day {
    font-size: 1.5rem;
    font-weight: 700; }

.schedule__time {
  font-size: 1.2rem;
  line-height: 1.3; }

.schedule__heading {
  font-weight: bold;
  color: #d4b86d;
  font-size: 1.5rem;
  line-height: 1.3; }
  .schedule__item--info .schedule__heading {
    color: #979691; }

.schedule__description a {
  color: #979691;
  font-weight: bold;
  text-decoration: none; }
  .schedule__item--info .schedule__description a {
    color: #979691; }

.schedule__session {
  display: inline-block;
  /*font-weight: bold;*/
  font-size: 1rem;
  text-transform: uppercase; }
  .schedule__session:before {
    content: '/';
    color: #d4b86d;
    margin: 0 0.75rem;
    font-size: 1.1rem; }

.popup {
  position: fixed;
  left: 50%;
  background-color: #fff;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 1200px;
  z-index: 100;
  flex-direction: column;
  pointer-events: none;
  opacity: 0;
  display: flex;
  transition: opacity 0.3s cubic-bezier(0.14, 0, 0.3, 0.78), transform 0.3s cubic-bezier(0.14, 0, 0.3, 0.78), top 0s 0.3s;
  top: 200%;
  transform: translate(-50%, -60%) scale(1) perspective(1px);
  transform-origin: center center;
  will-change: transform, opacity; }
  .popup.popup--tickets {
    max-width: 700px; }
  .popup.popup--videos {
    max-width: 700px;
    max-width: 100vmin; }
  .popup a {
    color: #d4b86d;
    text-decoration: none; }
    .popup a:hover, .popup a:focus {
      text-decoration: underline; }
  .popup--active {
    pointer-events: auto;
    opacity: 1;
    top: 50%;
    transform: translate(-50%, -50%) scale(1) perspective(1px);
    transition: opacity 0.3s cubic-bezier(0.14, 0, 0.3, 0.78), transform 0.3s cubic-bezier(0.14, 0, 0.3, 0.78), top 0s;
    transform-origin: center center; }
  @media screen and (min-width: 40em) {
    .popup {
      flex-direction: row; } }
  .popup__bg {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.5);
    top: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s; }
    .popup__bg--active {
      pointer-events: auto;
      opacity: 1;
      top: 0; }
  .popup h3 {
    font-family: "Bevan", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: #d4b86d;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: 400;
    position: relative;
    pointer-events: none; }
    @media screen and (min-width: 40em) {
      .popup h3 {
        margin-top: 0; } }
    .popup h3:after {
      content: '';
      position: absolute;
      height: 1px;
      background-color: #d4b86d;
      left: 0;
      bottom: 0;
      width: 8rem; }
  .popup p {
    font-size: 1.5rem; }
  .popup__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 66px;
    height: 66px;
    cursor: pointer;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out; }
    .popup__close:before, .popup__close:after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      background-color: transparent;
      height: 1px;
      width: 44px;
      background-color: #000;
      transition: background-color 0.3s ease-in-out; }
      @media screen and (min-width: 40em) {
        .popup__close:before, .popup__close:after {
          background-color: #fff; } }
    .popup__close:hover, .popup__close:focus {
      background-color: #fff; }
      .popup__close:hover:before, .popup__close:hover:after, .popup__close:focus:before, .popup__close:focus:after {
        background-color: #000; }
    .popup__close:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .popup__close:after {
      transform: translate(-50%, -50%) rotate(-45deg); }
  .popup__section {
    flex: 1;
    padding: 2rem; }
    @media screen and (min-width: 40em) {
      .popup__section {
        min-height: 400px; } }
    .popup__section--gold {
      background-color: #d4b86d;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center; }
  .popup input[type="email"] {
    background-color: #fff;
    font-family: "Titillium Web", Arial, sans-serif;
    color: #979691;
    padding: 0.8rem 1rem;
    width: 300px;
    border: solid 1px transparent; }
    .popup input[type="email"].mce_inline_error {
      border: solid 1px #b13f17 !important; }
  .popup label {
    display: block;
    text-transform: uppercase;
    font-family: "Titillium Web", Arial, sans-serif; }
  .popup div.mce_inline_error {
    background-color: transparent !important;
    color: #b13f17 !important;
    font-family: "Titillium Web", Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 400 !important;
    padding: 0.5rem 0 0 !important;
    margin: 0 !important; }
  .popup button[type="submit"] {
    margin: 0.5rem 0;
    padding: 0.8rem 2rem;
    font-family: "Titillium Web", Arial, sans-serif;
    font-size: 1.1rem;
    cursor: pointer;
    border: solid 1px #fff;
    color: #fff;
    position: relative;
    overflow: hidden;
    transition: color 0.25s ease-in-out; }
    .popup button[type="submit"] span {
      position: relative;
      z-index: 10; }
    .popup button[type="submit"]:before {
      content: '';
      position: absolute;
      top: -2rem;
      bottom: -2rem;
      right: -0.5rem;
      left: -0.5rem;
      background-color: #fff;
      z-index: 5;
      transform: rotate(-15deg) translateX(100%);
      transition: transform 0.25s ease-in-out; }
    .popup button[type="submit"]:hover {
      color: #d4b86d; }
      .popup button[type="submit"]:hover:before {
        transform: rotate(-15deg) translateX(0); }
  .popup #mce-success-response {
    font-family: "Titillium Web", Arial, sans-serif;
    margin-top: 0.5rem; }

.popup--tickets .popup__close {
  background-color: transparent; }
  .popup--tickets .popup__close:before, .popup--tickets .popup__close:after {
    background-color: #000; }
  .popup--tickets .popup__close:hover {
    background-color: #d4b86d; }
    .popup--tickets .popup__close:hover:before, .popup--tickets .popup__close:hover:after {
      background-color: #fff; }

.popup__close--video {
  background-color: rgba(255, 255, 255, 0.8); }
  .popup__close--video::before, .popup__close--video::after {
    background-color: #000; }
  .popup__close--video:hover {
    background-color: white; }

.venue {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 50em) {
    .venue {
      flex-direction: row; } }

.venue__content {
  flex: 1; }
  .venue__content h2 {
    font-size: 1.75rem; }
  @media screen and (min-width: 50em) {
    .venue__content {
      padding-right: 2rem; }
      .venue__content h2 {
        font-size: 2rem; } }

.venue__images {
  flex: 1;
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 50em) {
    .venue__images {
      padding-left: 2rem; } }

.venue__images__row {
  display: flex;
  margin-bottom: 1.5rem;
  flex-direction: column; }
  @media screen and (min-width: 50em) {
    .venue__images__row {
      flex-direction: row; } }
  .venue__images__row .venue__images__image {
    flex: 1; }
    .venue__images__row .venue__images__image img {
      width: 100%;
      max-width: 100%; }
    .venue__images__row .venue__images__image:not(:last-child) {
      margin-bottom: 3rem; }
    @media screen and (min-width: 50em) {
      .venue__images__row .venue__images__image img {
        width: auto; }
      .venue__images__row .venue__images__image:not(:last-child) {
        margin-bottom: 0;
        margin-right: 1.5rem; } }

.section--map {
  padding: 0; }
  .section--map .map {
    height: 200px;
    width: 100%;
    max-height: 50vh; }
  @media screen and (min-width: 50em) {
    .section--map {
      padding: 0 2rem; }
      .section--map .map {
        height: 400px; } }
  @media screen and (min-width: 70em) {
    .section--map .map {
      height: 600px; } }

.mouse {
  background: #fff linear-gradient(transparent 0%, transparent 50%, #d4b86d 50%, #d4b86d 100%);
  /*position: relative;*/
  width: 36.4px;
  height: 61.6px;
  background-size: 100% 100%;
  border-radius: 100px;
  background-size: 225%;
  -webkit-animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
          animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite; }
  .mouse:before, .mouse:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; }
  .mouse:before {
    width: 32.4px;
    height: 57.6px;
    background-color: #111;
    border-radius: 100px; }
  .mouse:after {
    background-color: #d4b86d;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    -webkit-animation: trackBallSlide 5s linear infinite;
            animation: trackBallSlide 5s linear infinite; }

@-webkit-keyframes colorSlide {
  0% {
    background-position: 0% 100%; }
  20% {
    background-position: 0% 0%; }
  21% {
    background-color: #fff; }
  29.99% {
    background-color: #d4b86d;
    background-position: 0% 0%; }
  30% {
    background-color: #fff;
    background-position: 0% 100%; }
  50% {
    background-position: 0% 0%; }
  51% {
    background-color: #fff; }
  59.99% {
    background-color: #d4b86d;
    background-position: 0% 0%; }
  60% {
    background-color: #fff;
    background-position: 0% 100%; }
  80% {
    background-position: 0% 0%; }
  81% {
    background-color: #fff; }
  89.99%, 100% {
    background-color: #d4b86d; } }

@keyframes colorSlide {
  0% {
    background-position: 0% 100%; }
  20% {
    background-position: 0% 0%; }
  21% {
    background-color: #fff; }
  29.99% {
    background-color: #d4b86d;
    background-position: 0% 0%; }
  30% {
    background-color: #fff;
    background-position: 0% 100%; }
  50% {
    background-position: 0% 0%; }
  51% {
    background-color: #fff; }
  59.99% {
    background-color: #d4b86d;
    background-position: 0% 0%; }
  60% {
    background-color: #fff;
    background-position: 0% 100%; }
  80% {
    background-position: 0% 0%; }
  81% {
    background-color: #fff; }
  89.99%, 100% {
    background-color: #d4b86d; } }

@-webkit-keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-20px); }
  6% {
    opacity: 1;
    transform: scale(0.9) translateY(5px); }
  14% {
    opacity: 0;
    transform: scale(0.4) translateY(40px); }
  15%, 19% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px); }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px); }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-20px); }
  36% {
    opacity: 1;
    transform: scale(0.9) translateY(5px); }
  44% {
    opacity: 0;
    transform: scale(0.4) translateY(40px); }
  45%, 49% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px); }
  58%, 59.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px); }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-20px); }
  66% {
    opacity: 1;
    transform: scale(0.9) translateY(5px); }
  74% {
    opacity: 0;
    transform: scale(0.4) translateY(40px); }
  75%, 79% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px); }
  88%, 100% {
    opacity: 1;
    transform: scale(1) translateY(-20px); } }

@keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-20px); }
  6% {
    opacity: 1;
    transform: scale(0.9) translateY(5px); }
  14% {
    opacity: 0;
    transform: scale(0.4) translateY(40px); }
  15%, 19% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px); }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px); }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-20px); }
  36% {
    opacity: 1;
    transform: scale(0.9) translateY(5px); }
  44% {
    opacity: 0;
    transform: scale(0.4) translateY(40px); }
  45%, 49% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px); }
  58%, 59.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px); }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-20px); }
  66% {
    opacity: 1;
    transform: scale(0.9) translateY(5px); }
  74% {
    opacity: 0;
    transform: scale(0.4) translateY(40px); }
  75%, 79% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px); }
  88%, 100% {
    opacity: 1;
    transform: scale(1) translateY(-20px); } }

@-webkit-keyframes nudgeMouse {
  0% {
    transform: translateY(0); }
  20% {
    transform: translateY(8px); }
  30% {
    transform: translateY(0); }
  50% {
    transform: translateY(8px); }
  60% {
    transform: translateY(0); }
  80% {
    transform: translateY(8px); }
  90% {
    transform: translateY(0); } }

@keyframes nudgeMouse {
  0% {
    transform: translateY(0); }
  20% {
    transform: translateY(8px); }
  30% {
    transform: translateY(0); }
  50% {
    transform: translateY(8px); }
  60% {
    transform: translateY(0); }
  80% {
    transform: translateY(8px); }
  90% {
    transform: translateY(0); } }

@-webkit-keyframes nudgeText {
  0% {
    transform: translateY(0); }
  20% {
    transform: translateY(2px); }
  30% {
    transform: translateY(0); }
  50% {
    transform: translateY(2px); }
  60% {
    transform: translateY(0); }
  80% {
    transform: translateY(2px); }
  90% {
    transform: translateY(0); } }

@keyframes nudgeText {
  0% {
    transform: translateY(0); }
  20% {
    transform: translateY(2px); }
  30% {
    transform: translateY(0); }
  50% {
    transform: translateY(2px); }
  60% {
    transform: translateY(0); }
  80% {
    transform: translateY(2px); }
  90% {
    transform: translateY(0); } }

@-webkit-keyframes colorText {
  21% {
    color: #fff; }
  30% {
    color: #d4b86d; }
  51% {
    color: #fff; }
  60% {
    color: #d4b86d; }
  81% {
    color: #fff; }
  90% {
    color: #d4b86d; } }

@keyframes colorText {
  21% {
    color: #fff; }
  30% {
    color: #d4b86d; }
  51% {
    color: #fff; }
  60% {
    color: #d4b86d; }
  81% {
    color: #fff; }
  90% {
    color: #d4b86d; } }

.around {
  max-width: 1500px;
  width: 100%;
  margin: 1.5rem auto;
  position: relative; }
  @media screen and (min-width: 40em) {
    .around {
      padding: 4rem 2rem;
      transition: 0.5s 1s; }
      .around.parallax {
        border-color: #d4b86d; } }

@media screen and (min-width: 40em) {
  .around--venue {
    padding: 6rem 4rem 4rem; } }

.around__header {
  margin: 0;
  transform: translate(0, -25%);
  text-align: center; }
  .around__header h2 {
    margin: 0;
    display: inline-block;
    background-color: #fff; }
  .around__header p {
    margin: 0; }
  .around__header__heading {
    font-family: "Titillium Web", Arial, sans-serif;
    display: inline-block;
    background-color: #fff;
    text-transform: uppercase;
    font-weight: 600; }
  .around__header__sub {
    font-style: italic;
    color: #d4b86d;
    font-size: 1.4rem;
    line-height: 1; }
  @media screen and (min-width: 40em) {
    .around__header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0; }
      .around__header__heading {
        padding: 0 1rem; }
      .around__header__sub {
        font-size: 1.8rem; } }

.around__border {
  position: absolute;
  background-color: #d4b86d;
  display: none; }
  @media screen and (min-width: 60em) {
    .around__border {
      display: block; } }
  .around__border--top, .around__border--bottom {
    top: 0;
    left: 0;
    height: 1px;
    width: 100%; }
    .parallax .around__border--top, .parallax .around__border--bottom {
      transform: scale(0, 1);
      opacity: 0; }
    .parallax--active .around__border--top, .parallax--active .around__border--bottom {
      transform: scale(1, 1);
      transition: 0.5s 0.3s;
      opacity: 1; }
  .around__border--right, .around__border--left {
    right: 0;
    top: 0;
    height: 100%;
    width: 1px; }
    .parallax .around__border--right, .parallax .around__border--left {
      transform: scale(1, 0);
      transform-origin: 0 0; }
    .parallax--active .around__border--right, .parallax--active .around__border--left {
      transform: scale(1, 1);
      transform-origin: 0 0;
      transition: 1s 0.8s; }
  .around__border--bottom {
    top: auto;
    bottom: 0; }
  .around__border--left {
    right: auto;
    left: 0; }

.press {
  display: flex;
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1rem;
  flex-direction: column;
  /*.avatar {
    width: 0;
  }*/ }
  .press img {
    max-width: 100%;
    height: auto;
    vertical-align: top; }
  @media screen and (min-width: 60em) {
    .press {
      flex-direction: row;
      padding: 0 2rem; } }

.press--single {
  margin-top: 4rem; }

.section--press {
  flex: 1; }

.post__thumbnail {
  margin-bottom: 1.5rem; }
  .post__thumbnail img {
    max-width: 100%; }

.post--press {
  position: relative;
  padding: 0 0 3rem;
  max-width: 700px;
  margin: 0 0 3rem; }
  .post--press:last-child {
    margin-bottom: 0; }
    @media screen and (min-width: 60em) {
      .post--press:last-child {
        margin-bottom: 10rem; } }
  .post--press:not(:last-child)::after {
    content: '';
    width: 100%;
    max-width: 150px;
    height: 1px;
    background-color: #d4b86d;
    position: absolute;
    bottom: 0; }

.post__author {
  display: flex;
  align-items: center; }
  .post__author__image img {
    border-radius: 50%;
    height: 100%;
    width: auto;
    max-width: auto;
    max-width: initial; }
  .post__author a {
    color: #d4b86d;
    text-decoration: none; }
    .post__author a:hover {
      text-decoration: underline; }
  .post__author__info {
    margin-left: 1.5rem; }
    .post__author__info p {
      margin: 0;
      font-family: "Titillium Web", Arial, sans-serif;
      line-height: 1.6; }

.wp-caption {
  max-width: 100%;
  width: auto !important; }
  .wp-caption p.wp-caption-text {
    color: #666;
    font-family: "Titillium Web", Arial, sans-serif;
    font-size: 1rem; }

.press h2 {
  color: #979691;
  margin-bottom: 1.5rem;
  padding-bottom: 0;
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 400;
  text-transform: none; }
  .press h2 a {
    color: #979691;
    text-decoration: none;
    transition: color 0.1s ease-in-out; }
    .press h2 a:hover {
      color: #d4b86d; }
  .press h2:after {
    display: none; }

.read-more {
  display: block;
  color: #d4b86d;
  font-family: "Titillium Web", Arial, sans-serif;
  text-decoration: none;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  position: relative;
  cursor: pointer; }
  .read-more span {
    position: relative;
    display: inline-block; }
    .read-more span:before, .read-more span:after {
      content: '';
      position: absolute;
      top: 50%;
      right: -1.5rem;
      height: 1px;
      width: 12px;
      transform: translateY(-50%);
      background-color: #d4b86d;
      transform-origin: 100% 0;
      transition: transform 0.25s ease-in-out;
      will-change: transform; }
    .read-more span:before {
      transform: translate(0, -50%) rotate(45deg); }
    .read-more span:after {
      transform: translate(0, -50%) rotate(-45deg); }
  .read-more:hover span {
    text-decoration: underline; }
    .read-more:hover span:before {
      transform: translate(60%, -50%) rotate(45deg); }
    .read-more:hover span:after {
      transform: translate(60%, -50%) rotate(-45deg); }

.post__date {
  color: #d4b86d;
  font-family: "Titillium Web", Arial, sans-serif;
  margin-bottom: 0; }

.post__tags {
  margin-top: 1rem;
  margin-bottom: 1.5rem; }
  .post__tags span {
    font-family: "Titillium Web", Arial, sans-serif; }
  .post__tags ul {
    padding: 0;
    margin: 0;
    display: inline-block; }
  .post__tags li {
    display: inline-block; }
  .post__tags a {
    display: inline-block;
    padding: 0.3rem 0.5rem;
    background-color: #eee;
    color: #5d5d5d;
    text-decoration: none;
    border-radius: 5px;
    font-family: "Titillium Web", Arial, sans-serif; }
    .post__tags a:before {
      content: '#'; }

.sidebar {
  padding: 0 1rem 3rem;
  width: 380px;
  flex: 0 1 auto; }
  .sidebar h3 {
    font-family: "Titillium Web", Arial, sans-serif;
    font-weight: 400; }

.past-news ul {
  padding: 0;
  margin: 0; }

.past-news li {
  display: block;
  margin-bottom: 1rem; }

.past-news a {
  display: inline-block;
  font-family: "Titillium Web", Arial, sans-serif;
  color: #d4b86d;
  text-decoration: none;
  font-size: 1.2rem; }
  .past-news a span {
    color: #5C5C5C;
    font-size: 1rem; }
  .past-news a:hover {
    text-decoration: underline; }

.materials ul {
  padding: 0;
  margin: 0; }

.materials li {
  display: block; }

.materials a {
  color: #5d5d5d;
  text-decoration: none;
  font-family: "Titillium Web", Arial, sans-serif;
  position: relative;
  display: inline-block; }
  .materials a:hover {
    color: #979691;
    text-decoration: underline; }
  .materials a:before, .materials a:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -1rem;
    height: 1px;
    width: 9px;
    transform: translateY(-50%);
    background-color: #5d5d5d;
    transform-origin: 100% 0;
    transition: transform 0.25s ease-in-out;
    will-change: transform; }
  .materials a:before {
    transform: translate(0, -50%) rotate(45deg); }
  .materials a:after {
    transform: translate(0, -50%) rotate(-45deg); }
  .materials a:hover:before, .materials a:hover:after {
    background-color: #979691; }
  .materials a:hover:before {
    transform: translate(60%, -50%) rotate(45deg); }
  .materials a:hover:after {
    transform: translate(60%, -50%) rotate(-45deg); }

.search-wrapper {
  position: relative; }

.search__input {
  width: 100%;
  border-radius: 100px;
  border: solid 1px #979691;
  padding: 0.5rem 1rem; }
  .search__input:focus {
    outline: none;
    border-color: #000; }

.search__submit {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  height: 25px;
  line-height: 1; }
  .search__submit svg {
    height: 100%;
    width: auto; }
    .search__submit svg g {
      fill: #979691; }
  .search__submit:before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 1px;
    left: -0.5rem;
    top: 50%;
    background-color: #ddd;
    transform: translateY(-50%); }

.section--ticket {
  padding: 2rem; }

.tickets-top {
  max-width: 1500px;
  width: 100%;
  text-align: left;
  margin-left: auto;
  margin-right: auto; }
  .tickets-top h1 {
    font-size: 2rem;
    line-height: 1.3;
    text-align: left;
    margin: 0 0 1.5rem; }
    @media screen and (min-width: 40em) {
      .tickets-top h1 {
        font-size: 2.5rem; } }
    @media screen and (min-width: 50em) {
      .tickets-top h1 {
        font-size: 3rem; } }
    @media screen and (min-width: 60em) {
      .tickets-top h1 {
        font-size: 3.5rem; } }
    @media screen and (min-width: 80em) {
      .tickets-top h1 {
        font-size: 5rem;
        max-width: 800px; } }

.tickets-main {
  flex: 1; }
  .tickets-main h2 {
    font-size: 1.75rem; }
  @media screen and (min-width: 50em) {
    .tickets-main {
      padding-right: 2rem; }
      .tickets-main h2 {
        font-size: 2rem; } }

.ticket-list {
  max-width: 100%;
  overflow: hidden; }

.ticket-row {
  height: auto;
  width: 100%;
  display: flex;
  margin-top: 10rem;
  flex-direction: row; }
  .ticket-row:nth-child(1) {
    margin-top: 4rem; }

.ticket-row__image {
  height: auto;
  width: 26em;
  max-width: 100%;
  margin-right: 3em;
  flex-shrink: 0;
  flex-grow: 0; }

.ticket-row__image__img {
  position: relative;
  display: block;
  width: 100%;
  height: auto; }
  .ticket-row__image__img > img {
    z-index: 1;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%); }
  .ticket-row__image__img:after {
    z-index: 2;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #cbb671;
    mix-blend-mode: multiply; }

.ticket-row__content {
  height: auto; }

.ticket-row__price {
  font-size: 2rem;
  color: #5d5d5d;
  font-family: "Titillium Web", Arial, sans-serif;
  font-weight: 400; }

.ticket-row__text__purchase-link {
  color: #5d5d5d;
  font-size: 1.2rem; }
  .ticket-row__text__purchase-link > a {
    font-family: "Titillium Web", Arial, sans-serif;
    color: #d4b86d;
    font-weight: 400;
    text-decoration: none; }
    .ticket-row__text__purchase-link > a:hover {
      text-decoration: underline; }

@media screen and (max-width: 97.75em) {
  .tickets-top {
    padding-left: 2rem;
    padding-right: 2rem; } }

@media screen and (max-width: 80em) {
  .ticket-row {
    display: block; }
  .ticket-row__image {
    margin-bottom: 2em; }
  .ticket-row__image__img {
    padding-top: 0;
    height: auto; }
    .ticket-row__image__img > img {
      position: relative; } }

@media screen and (min-width: 40em) {
  .around--ticket {
    padding: 6rem 4rem 4rem; } }

@media screen and (max-width: 45em) {
  .ticket-row {
    margin-top: 3rem;
    border-top: 2px solid #cbb671;
    padding-top: 3rem; }
    .ticket-row:nth-child(1) {
      margin-top: 0;
      border-top: none;
      padding-top: 3rem; }
  .ticket-row__image {
    width: 100%;
    margin-bottom: 2em; }
  .ticket-heading h2 {
    font-size: 1.75rem; } }

.ticket-row__post {
  flex: 1; }
  .ticket-row__post h2 {
    font-size: 1.75rem; }
  @media screen and (min-width: 50em) {
    .ticket-row__post {
      padding-right: 2rem; }
      .ticket-row__post h2 {
        font-size: 2rem; } }

/*
.post--tickets {

}

.tickets__included {
  margin-bottom: 1.5rem;
  h2 {
    padding-bottom: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-family: "Titillium Web", Arial, sans-serif;
    font-weight: 600;
    position: relative;
    &:after {
      display: none;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    display: block;
    position: relative;
    //height: 3rem;
    padding: 0.5rem 0 0.5rem 4rem;
    //line-height: 3rem;
    font-family: $font-sans;
    color: $color-gold;
    font-size: 1.3rem;

    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      height: 2.2rem;
      width: 2.2rem;
      top: 50%;
      left: 0;
      background: url('/app/themes/worldwhiskyforum/images/icons/check.svg') center center no-repeat;
      background-size: cover;
      transform: translateY(-50%);
    }
  }
}

.tickets__form {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.tickets__apply {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (min-width: 50em) {
    align-items: center;
    flex-direction: row;
  }
}



a.tickets__button__button, button.tickets__button__button {
  font-size: 1.1rem;
  padding: 0.8rem 3rem;
  border: solid 1px $color-gold;
  display: inline-block;
  color: $color-gold;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
  font-family: $font-sans;
  transition: color 0.5s cubic-bezier(0.3, 0.6, 0, 1);

  &:before {
    content: '';
    position: absolute;
    top: -1rem;
    bottom: -1rem;
    right: -1rem;
    left: -1rem;
    border: solid 1px $color-gold;
    background-color: $color-gold;
    transform: translateX(-100%);
    transition: transform 0.5s cubic-bezier(0.3, 0.6, 0, 1);
    will-change: transform;
    z-index: -1;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
    &:before {
      transform: none;
    }
  }
}

.tickets__limited {
  font-family: $font-sans;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin-top: 0.5rem;

  @media screen and (min-width: 50em) {
    margin-top: 0;
  }

  &:before {
    content: '/';
    color: $color-gold;
    margin-right: 0.75rem;
  }
}

.tickets__newsletter {
  margin-bottom: 1.5rem;
}

.tickets__newsletter-p {
  font-style: italic;
  max-width: 700px;
}
*/
.wwf-link {
  background-color: #02579a; }

.wwf-link__content {
  height: auto;
  padding: 6rem;
  display: flex;
  width: 1500px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }

.wwf-link__logo {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  position: relative;
  z-index: 1; }
  .wwf-link__logo img {
    display: block;
    width: 24em;
    max-width: 100%; }

.wwf-link__logo__date {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99;
  height: 8rem;
  width: 8rem;
  word-break: break-all;
  background-color: #014127;
  border-radius: 50%;
  transform: translateX(105%) rotate(12deg);
  display: flex;
  align-items: center; }
  .wwf-link__logo__date > span {
    display: inline-block;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-family: Titillium Web;
    text-transform: uppercase;
    color: #d4b86d;
    letter-spacing: 0em;
    font-weight: 200;
    margin: 0;
    padding: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: 400;
    color: #d4b86d;
    font-size: 1.5rem;
    line-height: 1.2;
    padding-bottom: 0.3em; }

.wwf-link__info {
  width: 50%;
  margin-left: auto;
  align-self: stretch;
  display: flex;
  justify-content: center;
  flex-direction: column; }

.wwf-link__sub-heading {
  font-family: Titillium Web;
  text-transform: uppercase;
  color: #d4b86d;
  letter-spacing: 0em;
  font-weight: 200;
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #d4b86d; }

.wwf-link__heading {
  font-family: "Bevan", sans-serif;
  text-transform: uppercase;
  color: #d4b86d;
  letter-spacing: 0.03em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-size: 2rem;
  color: #fff;
  position: relative;
  padding-bottom: 0.75rem;
  margin-bottom: 1.5rem;
  line-height: 1.4; }
  .wwf-link__heading:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 200px;
    height: 1px;
    background-color: #d4b86d;
    transition: 0.25s;
    transform-origin: 0 0;
    transition: 0.5s 0.2s; }
  .wwf-link__heading.parallax:after {
    transform: scale(0, 1); }
  .wwf-link__heading.parallax--active:after {
    transform: scale(1, 1); }

.wwf-link__text > p {
  color: #000;
  font-family: "Titillium Web", Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 1.3em; }

.wwf-link__button-container {
  padding-top: 2rem; }
  .wwf-link__button-container > a {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 1rem; }

@media screen and (max-width: 65em) {
  .wwf-link__content {
    display: block;
    padding: 4em; }
  .wwf-link__logo {
    justify-content: flex-start;
    width: 100%; }
    .wwf-link__logo img {
      width: 24em; }
  .wwf-link__info {
    padding-top: 4em;
    width: 100%; }
  .wwf-link__logo__date {
    top: 60%;
    font-size: 0.9em;
    height: 8em;
    width: 8em;
    transform: translateX(5%) rotate(12deg); } }

@media screen and (max-width: 36em) {
  .wwf-link__content {
    padding: 2em; }
  .wwf-link__logo img {
    width: 14em; }
  .wwf-link__logo__date {
    top: 0;
    font-size: 0.9em;
    height: 8em;
    width: 8em;
    transform: translateX(35%) rotate(12deg); }
  .wwf-link__info {
    padding-top: 4em;
    width: 100%; } }

.wwt-link {
  background-color: #025835; }

.wwt-link__content {
  height: auto;
  padding: 6rem;
  display: flex;
  width: 1500px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }

.wwt-link__logo {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  position: relative;
  z-index: 1; }
  .wwt-link__logo img {
    display: block;
    width: 24em;
    max-width: 100%; }

.wwt-link__logo__date {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99;
  height: 8rem;
  width: 8rem;
  word-break: break-all;
  background-color: #014127;
  border-radius: 50%;
  transform: translateX(105%) rotate(12deg);
  display: flex;
  align-items: center; }
  .wwt-link__logo__date > span {
    display: inline-block;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-family: Titillium Web;
    text-transform: uppercase;
    color: #d4b86d;
    letter-spacing: 0em;
    font-weight: 200;
    margin: 0;
    padding: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: 400;
    color: #d4b86d;
    font-size: 1.5rem;
    line-height: 1.2;
    padding-bottom: 0.3em; }

.wwt-link__info {
  width: 50%;
  margin-left: auto;
  align-self: stretch;
  display: flex;
  justify-content: center;
  flex-direction: column; }

.wwt-link__sub-heading {
  font-family: Titillium Web;
  text-transform: uppercase;
  color: #d4b86d;
  letter-spacing: 0em;
  font-weight: 200;
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #d4b86d; }

.wwt-link__heading {
  font-family: "Bevan", sans-serif;
  text-transform: uppercase;
  color: #d4b86d;
  letter-spacing: 0.03em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-size: 2rem;
  color: #fff;
  position: relative;
  padding-bottom: 0.75rem;
  margin-bottom: 1.5rem;
  line-height: 1.4; }
  .wwt-link__heading:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 200px;
    height: 1px;
    background-color: #d4b86d;
    transition: 0.25s;
    transform-origin: 0 0;
    transition: 0.5s 0.2s; }
  .wwt-link__heading.parallax:after {
    transform: scale(0, 1); }
  .wwt-link__heading.parallax--active:after {
    transform: scale(1, 1); }

.wwt-link__text > p {
  color: #000;
  font-family: "Titillium Web", Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 1.3em; }

.wwt-link__button-container {
  padding-top: 2rem; }
  .wwt-link__button-container > a {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 1rem; }

@media screen and (max-width: 65em) {
  .wwt-link__content {
    display: block;
    padding: 4em; }
  .wwt-link__logo {
    justify-content: flex-start;
    width: 100%; }
    .wwt-link__logo img {
      width: 24em; }
  .wwt-link__info {
    padding-top: 4em;
    width: 100%; }
  .wwt-link__logo__date {
    top: 60%;
    font-size: 0.9em;
    height: 8em;
    width: 8em;
    transform: translateX(5%) rotate(12deg); } }

@media screen and (max-width: 36em) {
  .wwt-link__content {
    padding: 2em; }
  .wwt-link__logo img {
    width: 14em; }
  .wwt-link__logo__date {
    top: 0;
    font-size: 0.9em;
    height: 8em;
    width: 8em;
    transform: translateX(35%) rotate(12deg); }
  .wwt-link__info {
    padding-top: 4em;
    width: 100%; } }

.wwt-ticket-link {
  padding-bottom: 0em; }

.wwt-ticket-link__content {
  width: 1500px;
  max-width: 100%;
  min-height: 15rem;
  background-color: #025835;
  margin-left: auto;
  margin-right: auto;
  padding: 6rem;
  position: relative; }

.wwt-ticket-link__sub-heading {
  font-family: Titillium Web;
  text-transform: uppercase;
  color: #d4b86d;
  letter-spacing: 0em;
  font-weight: 200;
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #d4b86d; }

.wwt-ticket-link__heading {
  font-family: "Bevan", sans-serif;
  text-transform: uppercase;
  color: #d4b86d;
  letter-spacing: 0.03em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #d4b86d;
  line-height: 1.4; }

.wwt-ticket-link__text {
  margin-top: 1rem;
  max-width: 40rem; }
  .wwt-ticket-link__text > p {
    color: #000;
    font-family: "Titillium Web", Arial, sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: left;
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 1.3em; }

.wwt-ticket-link__button-container {
  position: absolute;
  right: 6em;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  height: auto;
  z-index: 99;
  font-size: 1em; }

@media screen and (max-width: 50em) {
  .wwt-ticket-link__content {
    padding: 2em; }
  .wwt-ticket-link__button-container {
    padding-top: 1em;
    position: relative;
    right: 0;
    transform: translateY(0%);
    display: flex;
    justify-content: center; } }

.wwt-about__content {
  width: 1500px;
  padding: 6em;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }

.wwt-about__text {
  max-width: 44rem;
  margin-left: auto; }

@media screen and (max-width: 50em) {
  .wwt-about__content {
    width: 100%;
    padding: 2em; } }

.section--archive-summary {
  padding-left: 2rem;
  padding-right: 2rem; }
  @media screen and (min-width: 50em) {
    .section--archive-summary {
      padding-left: 0;
      padding-right: 0; } }

.around--archive-summary {
  padding-top: 2rem;
  padding-bottom: 0; }

.archive-summary {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 60em) {
    .archive-summary {
      flex-direction: row; } }

.archive-summary__content {
  flex: 1; }
  .archive-summary__content h2 {
    font-size: 1.75rem; }
  @media screen and (min-width: 60em) {
    .archive-summary__content {
      padding-right: 2rem; }
      .archive-summary__content h2 {
        font-size: 2rem; } }

.archive-summary__images {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 2rem; }
  @media screen and (min-width: 60em) {
    .archive-summary__images {
      padding-left: 2rem;
      padding-top: 0; } }

.archive-summary__images__row {
  display: flex;
  margin-bottom: 1.5rem;
  flex-direction: column; }
  @media screen and (min-width: 60em) {
    .archive-summary__images__row {
      flex-direction: row; } }
  .archive-summary__images__row .archive-summary__images__image {
    flex: 1; }
    .archive-summary__images__row .archive-summary__images__image img {
      width: 100%;
      max-width: 100%; }
    .archive-summary__images__row .archive-summary__images__image:not(:last-child) {
      margin-bottom: 3rem; }
    @media screen and (min-width: 60em) {
      .archive-summary__images__row .archive-summary__images__image img {
        width: auto; }
      .archive-summary__images__row .archive-summary__images__image:not(:last-child) {
        margin-bottom: 0;
        margin-right: 1.5rem; } }

.around-archive-venue {
  border-top: 1px solid #d4b86d;
  padding-top: 2rem; }

.around__header--archive-venue {
  transform: translateY(-0.8rem); }

.archive-gallery-section {
  padding-left: 2rem;
  padding-right: 2rem; }
  @media screen and (min-width: 50em) {
    .archive-gallery-section {
      padding-left: 0;
      padding-right: 0; } }

.around--archive-gallery {
  border-top: 1px solid #d4b86d;
  padding-top: 4rem;
  padding-bottom: 0; }

.around__header--archive-gallery {
  transform: translateY(-0.8rem); }

.archive-gallery {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 60em) {
    .archive-gallery {
      flex-direction: row; } }

.archive-gallery__content {
  flex: 1; }
  .archive-gallery__content h2 {
    font-size: 1.75rem; }
  @media screen and (min-width: 60em) {
    .archive-gallery__content {
      padding-right: 2rem; }
      .archive-gallery__content h2 {
        font-size: 2rem; } }

.archive-gallery__images {
  flex: 1;
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 60em) {
    .archive-gallery__images {
      padding-left: 2rem; } }

.archive-gallery__images__row {
  display: flex;
  margin-bottom: 1.5rem;
  flex-direction: column; }
  @media screen and (min-width: 50em) {
    .archive-gallery__images__row {
      flex-direction: row;
      gap: 1rem; } }
  .archive-gallery__images__row .archive-gallery__images__image {
    flex: 1; }
    .archive-gallery__images__row .archive-gallery__images__image img {
      width: 100%;
      max-width: 100%; }
    .archive-gallery__images__row .archive-gallery__images__image:not(:last-child) {
      margin-bottom: 3rem; }
    @media screen and (min-width: 60em) {
      .archive-gallery__images__row .archive-gallery__images__image img {
        width: auto; }
      .archive-gallery__images__row .archive-gallery__images__image:not(:last-child) {
        margin-bottom: 0;
        margin-right: 1.5rem; } }

.section--map {
  padding: 0; }
  .section--map .map {
    height: 200px;
    width: 100%;
    max-height: 50vh; }
  @media screen and (min-width: 60em) {
    .section--map {
      padding: 0 2rem; }
      .section--map .map {
        height: 400px; } }
  @media screen and (min-width: 70em) {
    .section--map .map {
      height: 600px; } }

.around-archive-venue {
  border-top: 1px solid #d4b86d;
  padding-top: 2rem; }

.around__header--archive-venue {
  transform: translateY(-0.8rem); }

.archive-hihglight-section {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4rem;
  max-width: 1500px; }
  @media screen and (min-width: 50em) {
    .archive-hihglight-section {
      padding-left: 2rem;
      padding-right: 2rem;
      margin-left: auto;
      margin-right: auto; } }

.button-rounded {
  border: 1px solid #979691;
  border-radius: 2em;
  color: #979691;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  font-size: 1em;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  cursor: pointer; }
  .button-rounded > span {
    font-family: Titillium Web;
    text-transform: uppercase;
    color: #d4b86d;
    letter-spacing: 0em;
    font-weight: 200;
    margin: 0;
    padding: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: 400;
    transition: color 0.3s ease-in-out;
    text-transform: capitalize;
    font-size: 1.3em; }
  .button-rounded:hover {
    background-color: #d4b86d; }
  .button-rounded:hover > span {
    color: #fff; }
  .button-rounded:focus {
    outline: none; }

.button-rounded.button-rounded--mod-solid-white {
  background-color: #fff; }
  .button-rounded.button-rounded--mod-solid-white > span {
    color: #014127; }
  .button-rounded.button-rounded--mod-solid-white:hover {
    border: 1px solid #d4b86d;
    background-color: #d4b86d; }
  .button-rounded.button-rounded--mod-solid-white:hover > span {
    color: #fff; }

.button-rounded.button-rounded--mod-gold {
  border: 1px solid #d4b86d; }
  .button-rounded.button-rounded--mod-gold > span {
    color: #d4b86d; }
  .button-rounded.button-rounded--mod-gold:hover {
    border: 1px solid #d4b86d;
    background-color: #d4b86d; }
  .button-rounded.button-rounded--mod-gold:hover > span {
    color: #fff; }

.button-rounded.button-rounded--mod-black {
  border: 1px solid #000; }
  .button-rounded.button-rounded--mod-black > span {
    color: #000; }
  .button-rounded.button-rounded--mod-black:hover {
    border-color: #d4b86d;
    background-color: #d4b86d; }
  .button-rounded.button-rounded--mod-black:hover > span {
    color: #fff; }

.cookie-msg__wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999978;
  display: block;
  height: auto;
  display: none; }
  .cookie-msg__wrapper::before {
    content: "";
    background: #fff;
    opacity: 0.95;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; }
  .cookie-msg__wrapper.cookie-msg__wrapper--mod-adjust {
    bottom: 2em; }
  .cookie-msg__wrapper.cookie-msg__wrapper--is-open {
    display: block; }

.cookie-msg__content {
  position: relative;
  z-index: 2;
  padding-top: 2em;
  padding-bottom: 4em;
  padding-left: 3em;
  padding-right: 3em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }

.cookie-msg__header {
  padding-bottom: 1em;
  width: 100%;
  flex-basis: 100%; }

.cookie-msg__header__heading {
  color: #000;
  font-family: "Titillium Web", Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 1.5em;
  color: #000;
  line-height: 1.2;
  font-weight: 400; }

.cookie-msg__text {
  max-width: 100%;
  width: auto;
  margin-right: 3em; }

.cookie-msg__text p {
  color: #000;
  font-family: "Titillium Web", Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 1em;
  color: #000;
  line-height: 1.62; }

.cookie-msg__text p a {
  color: #000;
  text-decoration: underline; }

.cookie-msg__btn__wrapper {
  max-width: 100%;
  width: auto;
  padding-top: 1em;
  display: flex;
  align-items: center; }

.cookie-msg__btn--accept {
  display: block;
  margin-right: 2em; }

.cookie-msg__btn--deny {
  color: #000;
  font-family: "Titillium Web", Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 1em;
  color: #5d5d5d;
  line-height: 1.62;
  display: block;
  cursor: pointer; }

@media screen and (max-width: 65em) {
  .cookie-msg__wrapper.cookie-msg__wrapper--mod-adjust {
    bottom: 0; }
  .cookie-msg__content {
    padding-top: 3em;
    padding-bottom: 3em;
    padding-left: 3em;
    padding-right: 3em;
    width: 100%; }
  .cookie-msg__header__heading {
    font-size: 2em; }
  .cookie-msg__text {
    flex-basis: 100%; }
  .cookie-msg__text p {
    font-size: 1.3em; }
  .cookie-msg__btn__wrapper {
    margin-top: 1em;
    flex-basis: 100%; } }

@media screen and (max-width: 36em) {
  .cookie-msg__wrapper.cookie-msg__wrapper--mod-adjust {
    bottom: 0; }
  .cookie-msg__content {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em; }
  .cookie-msg__header {
    padding-bottom: 0.5em; }
  .cookie-msg__header__heading {
    font-size: 1em; }
  .cookie-msg__text p {
    font-size: 1em; }
  .cookie-msg__btn {
    font-size: 0.8em; } }

section.test {
  height: 400px;
  background-color: #000;
  position: relative; }
  section.test:after {
    content: 'PLACEHOLDER';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    letter-spacing: 0.6em; }

.site-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /*border: solid 1rem #fff;*/
  pointer-events: none;
  box-shadow: inset 1rem -1rem 0 #f00, inset -1rem 1rem 0 #f00; }

.border {
  position: fixed;
  background-color: #fff;
  transition: transform 0.5s;
  display: none;
  z-index: 10; }
  @media screen and (min-width: 50em) {
    .border {
      display: block; } }
  .border.top, .border.bottom {
    top: 0;
    left: 0;
    right: 0;
    height: 1rem;
    transform: scale(1, 0);
    transform-origin: top; }
    @media screen and (min-width: 70em) {
      .border.top, .border.bottom {
        height: 1.5rem; } }
    @media screen and (min-width: 90em) {
      .border.top, .border.bottom {
        height: 2rem; } }
  .border.right, .border.left {
    top: 0;
    bottom: 0;
    right: 0;
    width: 1rem;
    transform: scale(0, 1);
    transform-origin: right; }
    @media screen and (min-width: 70em) {
      .border.right, .border.left {
        width: 1.5rem; } }
    @media screen and (min-width: 90em) {
      .border.right, .border.left {
        width: 2rem; } }
  .border.bottom {
    top: auto;
    bottom: 0;
    transform-origin: bottom; }
  .border.left {
    left: 0;
    right: auto;
    transform-origin: left;
    z-index: 10; }
  .border.border {
    transform: scale(1, 1); }
  .border__content {
    position: absolute;
    left: 0;
    top: 50%;
    color: #d4b86d;
    width: 100vh;
    height: 2rem;
    z-index: 10000;
    transform: rotate(-90deg) translate(-50%, -50%);
    transform-origin: 0 -50%;
    text-align: center;
    text-transform: uppercase;
    font-family: "Titillium Web", Arial, sans-serif;
    align-items: center;
    justify-content: center;
    display: none; }
    @media screen and (min-width: 90em) {
      .border__content {
        display: flex; } }
  .border__line {
    height: 1px;
    width: 3rem;
    display: inline-block;
    background-color: #d4b86d;
    margin: 0 1rem; }
